import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Heading, useEloise } from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import pepes from "../../assets/pepes.png";
import { PeopleInput, PersonDisplay } from "./peopleInput";

export interface ParticipantsProps {}
const options = [
  "Buyer",
  "Seller",
  "Seller's agent",
  "Buyer's agent",
  "Seller transaction coordinator",
  "Buyer transaction coordinator",
  "Seller closing attorney",
  "Buyer closing attorney",
  "Title company",
  "Escrow company",
  "Transaction auditor",
  "Lender",
  "Facilitator",
] as const;

export const Participants: React.FC<ParticipantsProps> = ({}) => {
  const { id } = useParams();
  const { logic, theme } = useEloise();

  const docPath = `transactions/${id}`;

  const [add, setAdd] = useState(false);

  const [participants, setParticipants] = logic.fb.hooks.useThrottleField(
    docPath,
    "participants"
  );
  const addParticipant = (x: any) => {
    const id = uuidv4();

    setParticipants(
      participants && participants.length ? [...participants, x] : [x]
    );
  };

  return (
    <Col lg={4}>
      <Heading
        size={3}
        style={{ fontWeight: 400 }}
        align="left">
        Participants
      </Heading>
      <Row className="mb-5">
        <Col
          lg={9}
          className="text-center mx-auto">
          {participants && participants.length > 0 ? (
            <>
              {participants.map((participant: any, index: number) => {
                return <PersonDisplay {...participant} />;
              })}
            </>
          ) : (
            <img
              width="200"
              height="150"
              src={pepes}
            />
          )}
          <Row className="mt-3">
            <PeopleInput
              types={options}
              onAdd={addParticipant}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
