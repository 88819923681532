import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  Button,
  EloiseWidget,
  FlatList,
  Heading,
  useEloise,
} from "typed-component-gallery";
import house from "../../assets/house.png";
import CalendarComponent from "../widgets/calendar";
import { Event } from "../widgets/event";
import { CardCol } from "../widgets/listingCard";

const ListingCard: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  select?: (data: any) => void;
}> = ({ data, update, select }) => {
  const [offers, setOffers] = useState<any[]>([]);
  const navigate = useNavigate();
  const { theme, logic } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    const test = await logic.fb.docs.getUserCollection(
      "listings/" + data.id + "/offers"
    );
    setOffers(test);
  }, []);
  return (
    <CardCol
      className="text-center m-3"
      style={{ minWidth: "20vw" }}>
      <EloiseWidget
        eloiseIntel={{
          desc: "A Card displaying information for a real estate listing ",
          title: "listing card",
        }}>
        <>
          <img
            src={house}
            height={150}
          />
          <Row className=" my-3">
            <Col
              lg={11}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                style={{
                  fontWeight: "400",
                }}>{`${data.data.addy.street}`}</Heading>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Price: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {data.data.price}
                  </Heading>
                </Col>
              </Row>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Offers: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {offers.length.toString()}
                  </Heading>
                </Col>
              </Row>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Visitors: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {"0"}
                  </Heading>
                </Col>
              </Row>
              <Row className="my-3">
                <Col
                  lg={6}
                  className="mx-auto">
                  <Button
                    onClick={() => {
                      select !== undefined
                        ? select(data)
                        : console.log("not given");
                    }}>
                    Select
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      </EloiseWidget>
    </CardCol>
  );
};

const NewEvent: React.FC<{ select: (data: any) => void }> = ({ select }) => {
  return (
    <div style={{ minHeight: "600px" }}>
      <Heading
        style={{ textDecoration: "2px solid underline" }}
        size={3}>
        New Event
      </Heading>
      <FlatList
        select={select}
        Component={ListingCard}
        path="listings"
      />
    </div>
  );
};

export const Calendar: React.FC = () => {
  const { logic } = useEloise();

  const [dates, setDates] = useState<Array<Event>>([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleNew = (x: any) => {
    console.log(x);
    setShowModal(true); // Show modal when handleNew is called
  };

  logic.hooks.useAsyncEffect(async () => {
    let test = await logic.fb.docs.getUserCollection("events");
    if (test) {
      setDates(test);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false); // Function to hide modal
  };

  const chooseList = (data: any) => {
    console.log(data);
  };

  return (
    <Container>
      <Row>
        <CalendarComponent
          Events={dates}
          onNew={handleNew}
          onSelect={handleNew}
        />
      </Row>

      {/* The Modal */}
      <Modal
        style={{ minWidth: "75vw" }}
        show={showModal}
        onHide={handleClose}>
        <Modal.Body>
          <NewEvent select={chooseList} />
          <Row className="g-1">
            <Col lg={4}></Col>
            <Col lg={3}>
              <Button
                color="secondary"
                onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col lg={5}>
              <Button onClick={handleClose}>Save Changes</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
