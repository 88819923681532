import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  Button,
  DBList,
  DBTable,
  Heading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";

const HomeDiv = styled.div`
  margin: 10px;
  padding: 0 10px;
  border: 1px solid grey;
  border-radius: 8px;
  min-height: 78vh;
  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }
`;
const CardCol = styled(Col)`
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  border-radius: 8px;
  padding: 5px;
`;

const ListingCardOffers: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
}> = ({ data, update }) => {
  const { theme } = useEloise();
  const navigate = useNavigate();

  return (
    <CardCol
      lg={12}
      className="text-center">
      <Row className=" my-3">
        <Col lg={4}>
          <Heading
            size={2}
            color="black">{`${data.data.addy}`}</Heading>
        </Col>

        <Col lg={4}>
          <Row>
            <Col lg={7}>
              <Heading
                size={2}
                align="right"
                color="black">{`Listing Price: `}</Heading>
            </Col>

            <Col lg={3}>
              <Heading
                size={2}
                align="left"
                color="black"
                style={{
                  fontWeight: "400",
                  textDecoration: "underline",
                  textDecorationColor: theme.secondary,
                }}>
                {data.data.price}
              </Heading>
            </Col>
          </Row>
        </Col>
        <Col lg={1}></Col>
        <Col
          lg={2}
          style={{ marginTop: "-15px" }}>
          <Button
            onClick={() =>
              navigate("/create-offer/" + data.id + "/" + uuidv4())
            }>
            Add Offer
          </Button>
        </Col>
      </Row>

      <Row>
        <DBTable
          path={"listings/" + data.id + "/offers"}
          fields={"manual"}
          manualDefault={[
            "buyerName",
            "buyerEmail",
            "preQualified",
            "financingType",
            "offerPrice",
            "closingDate",
            "buyerAgentName",
            "buyerAgentEmail",
            "buyerAgentPhone",
          ]}
        />
      </Row>
    </CardCol>
  );
};

const EmptyMessage = () => {
  const { theme } = useEloise();
  const navigate = useNavigate();
  return (
    <Row className="mt-5">
      <Col
        lg={10}
        className="mx-auto">
        <Heading size={3}>You don't have any offers yet.</Heading>
        <Heading size={2}>Go to Listings to add one</Heading>
        <Row className="mt-4">
          <Col
            lg={4}
            className="mx-auto">
            <Button onClick={() => navigate("/listings")}>Listings</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const Offers: React.FC<{}> = ({}) => {
  const { theme, logic } = useEloise();

  return (
    <>
      <Row className="mx-auto">
        <Col lg={12}>
          <HomeDiv>
            <Row>
              <div
                className="col-lg-3 "
                style={{
                  backgroundColor: theme.primary,
                  borderRadius: "8px 0 8px 0",
                  padding: "13px 15px",
                  marginTop: "0",
                }}>
                <Heading
                  color="white"
                  size={3}>
                  My Offers
                </Heading>
              </div>

              <Col lg={6}></Col>
            </Row>
            <Row className="mx-auto my-5">
              <DBList
                path="listings"
                Component={ListingCardOffers}
                Empty={EmptyMessage}
              />
            </Row>
          </HomeDiv>
        </Col>
      </Row>
    </>
  );
};
