import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  Button,
  EloiseWidget,
  Heading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import house from "../../assets/house.png";

export const CardCol = styled(Col)`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  padding: 5px;
  max-width: 350px;
`;

export const ListingCard: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
}> = ({ data, update }) => {
  const [offers, setOffers] = useState<any[]>([]);
  const navigate = useNavigate();
  const { theme, logic } = useEloise();

  logic.hooks.useAsyncEffect(async () => {
    const test = await logic.fb.docs.getUserCollection(
      "listings/" + data.id + "/offers"
    );
    setOffers(test);
  }, []);
  return (
    <CardCol className="text-center mx-3 my-2">
      <EloiseWidget
        eloiseIntel={{
          desc: "A Card displaying information for a real estate listing ",
          title: "listing card",
        }}>
        <>
          <img
            src={house}
            height={150}
          />
          <Row className=" my-3">
            <Col
              lg={11}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                style={{
                  fontWeight: "400",
                }}>{`${data.data.addy.street}`}</Heading>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Price: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {data.data.price}
                  </Heading>
                </Col>
              </Row>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Offers: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {offers.length.toString()}
                  </Heading>
                </Col>
              </Row>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    size={2}
                    align="right"
                    color="black">{`Visitors: `}</Heading>
                </Col>

                <Col lg={6}>
                  <Heading
                    size={2}
                    align="left"
                    color="black"
                    style={{
                      fontWeight: "400",
                      textDecoration: "underline",
                      textDecorationColor: theme.secondary,
                    }}>
                    {"0"}
                  </Heading>
                </Col>
              </Row>
              <Row className="my-3">
                <Col lg={6}>
                  <Button
                    onClick={() => navigate("listing-details/" + data.id)}>
                    Manage
                  </Button>
                </Col>
                <Col lg={6}>
                  <Button
                    onClick={() =>
                      navigate("/create-offer/" + data.id + "/" + uuidv4())
                    }>
                    Add Offer
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      </EloiseWidget>
    </CardCol>
  );
};
