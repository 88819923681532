import React, { useState } from "react";
import {
  Button as BSButton,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router";
import { Button, Heading, useEloise } from "typed-component-gallery";

interface GenLinkProps {
  color: "primary" | "secondary" | "accent" | "accent2";
  close: Function;
  docId: string;
  message: string;
}

export const GenLink: React.FC<GenLinkProps> = ({
  color,
  close,
  docId,
  message,
}) => {
  const { logic } = useEloise();
  const { id } = useParams();
  const [link, setLink] = useState("http://example.com");

  logic.hooks.useAsyncEffect(async () => {
    if (id) {
      let userId = await logic.fb.getAuthenticatedUserUid();

      setLink(`http://localhost:3000/upload-file/${id}/${docId}/${userId}`);
    }
  }, []);
  logic.hooks.useAsyncEffect(async () => {
    let x = `${id ?? ""}/${docId}`;
    let uid = await logic.fb.getAuthenticatedUserUid();
    const currentDate = new Date(); // Get the current date
    const futureDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + 3)
    );

    if (uid) {
      let data = {
        sharer: uid,
        expires: futureDate,
        message,
      };
      let test = await logic.fb.storage.generateUploadLink(x, data);
    }
  }, []);

  const onCopy = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Link copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Container>
      <Row>
        <Heading
          size={3}
          color="black">
          Share this link to collect documents
        </Heading>
        <Heading
          size={1}
          color="black">
          This link will be valid for 3 months
        </Heading>
      </Row>
      <Row className="my-4">
        <Col
          lg={9}
          className="mx-auto">
          <InputGroup className="mb-3 ">
            <FormControl
              value={link}
              readOnly
            />
            <BSButton
              variant="outline-secondary"
              onClick={onCopy}>
              Copy
            </BSButton>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          className="mx-auto">
          <Button
            color={color}
            onClick={() => close()}>
            Done
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
