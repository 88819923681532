import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading } from "typed-component-gallery";

import { HomeDiv } from "../pages/transactions";
import Timeline from "./timelineNav";

interface DotProps {
  color: string;
  onClick?: () => void;
  text: string;
  select?: boolean;
}

interface TrannyRowProps {
  width: number;
  children: any;
  color: string;
  heading: string;
  getDots: (x: string) => DotProps[];
}

export const TrannyRow: React.FC<TrannyRowProps> = ({
  width,
  children,
  color,
  heading,
  getDots,
}) => {
  return (
    <>
      <Row className="mx-auto ">
        <Col
          lg={width}
          className="mx-auto"
          style={{ transition: "all ease 1s" }}>
          <HomeDiv
            style={{ minHeight: "78vh" }}
            className="container-fluid">
            <Row className="g-0 mb-3">
              {/* Header  */}
              <div
                className={`mx-auto  order-sm-1   order-xs-1 order-lg-2  col-lg-${
                  width === 6 ? 6 : 4
                } `}
                style={{
                  backgroundColor: color,
                  borderRadius: "0 0 8px 8px",
                  padding: "13px 15px",
                  marginTop: "0",
                }}>
                <Heading
                  color="white"
                  size={3}>
                  {heading}
                </Heading>
              </div>
            </Row>
            <Row>{children}</Row>
            <div
              style={{
                position: "absolute",
                width: "1000px",
                height: "10px",
                bottom: "-5vh",
              }}></div>
          </HomeDiv>
        </Col>
      </Row>
      <Row>
        <Col
          lg={11}
          className="mx-auto">
          <Timeline dots={getDots(heading)} />
        </Col>
      </Row>
    </>
  );
};
