import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import {
  Button,
  DropDown,
  Heading,
  Input,
  LargeTextInput,
  View,
  useEloise,
} from "typed-component-gallery";
import house from "../../assets/houseOffer.png";
import LoadingBlack from "../../assets/loadingBlack.svg";
import { Checkbox } from "../widgets/checkBox";

const HomeDiv = styled.div`
  margin: 10px;
  border: 1px solid grey;
  border-radius: 8px;

  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }
`;

export const OfferCreate: React.FC<{}> = ({}) => {
  const { listingId, offerId } = useParams();
  const { logic } = useEloise();
  const docPath = `listings/${listingId}/offers/${offerId}`;

  const [buyerAgentName, setBuyerAgentName] = logic.fb.hooks.useThrottleField(
    docPath,
    "buyerAgentName"
  );
  const [buyerAgentEmail, setBuyerAgentEmail] = logic.fb.hooks.useThrottleField(
    docPath,
    "buyerAgentEmail"
  );
  const [buyerAgentPhone, setBuyerAgentPhone] = logic.fb.hooks.useThrottleField(
    docPath,
    "buyerAgentPhone"
  );
  const [buyerName, setBuyerName] = logic.fb.hooks.useThrottleField(
    docPath,
    "buyerName"
  );
  const [buyerEmail, setBuyerEmail] = logic.fb.hooks.useThrottleField(
    docPath,
    "buyerEmail"
  );
  const [shareActivity, setShareActivity] = logic.fb.hooks.useThrottleField(
    docPath,
    "shareActivity"
  );
  const [coverLetter, setCoverLetter] = logic.fb.hooks.useThrottleField(
    docPath,
    "coverLetter"
  );
  const [offerPrice, setOfferPrice] = logic.fb.hooks.useThrottleField(
    docPath,
    "offerPrice"
  );
  const [depositPercent, setDepositPercent] = logic.fb.hooks.useThrottleField(
    docPath,
    "depositPercent"
  );
  const [depositAmount, setDepositAmount] = logic.fb.hooks.useThrottleField(
    docPath,
    "depositAmount"
  );
  const [financingType, setFinancingType] = logic.fb.hooks.useThrottleField(
    docPath,
    "financingType"
  );
  const [loanAmount, setLoanAmount] = logic.fb.hooks.useThrottleField(
    docPath,
    "loanAmount"
  );
  const [preQualified, setPreQualified] = logic.fb.hooks.useThrottleField(
    docPath,
    "preQualified"
  );
  const [additionalFinancing, setAdditionalFinancing] =
    logic.fb.hooks.useThrottleField(docPath, "additionalFinancing");
  const [financingAmount, setFinancingAmount] = logic.fb.hooks.useThrottleField(
    docPath,
    "financingAmount"
  );
  const [financeContingency, setFinanceContingency] =
    logic.fb.hooks.useThrottleField(docPath, "financeContingency");
  const [financeWaived, setFinanceWaived] = logic.fb.hooks.useThrottleField(
    docPath,
    "financeWaived"
  );
  const [appraisalContingency, setAppraisalContingency] =
    logic.fb.hooks.useThrottleField(docPath, "appraisalContingency");
  const [appraisalWaived, setAppraisalWaived] = logic.fb.hooks.useThrottleField(
    docPath,
    "appraisalWaived"
  );
  const [inspectionContingency, setInspectionContingency] =
    logic.fb.hooks.useThrottleField(docPath, "inspectionContingency");
  const [inspectionWaived, setInspectionWaived] =
    logic.fb.hooks.useThrottleField(docPath, "inspectionWaived");
  const [closingDate, setClosingDate] = logic.fb.hooks.useThrottleField(
    docPath,
    "closingDate"
  );
  const [loading, setLoading] = useState<boolean>(false); //
  const [autoFillVal, setAutoFillVal] = useState<string>();

  const navigate = useNavigate();

  const save = async () => {
    navigate("/offers");
  };

  // const autoFill = async () =>{
  //     if(autoFillVal){
  //         setLoading(true)
  //     const response =  await logic.zillowParse(autoFillVal)

  //    if(response){
  //     let test = parsePropertyString(response)
  //     setAllStates(test)
  //     setLoading(false)
  //    }
  //     }

  // }

  const { theme } = useEloise();
  const financingOptions = ["Conventional", "FHA", "VA", "USDA"];

  return (
    <>
      <Row className="mx-auto">
        <Col lg={12}>
          <HomeDiv>
            <Row className="mx-auto  mb-4">
              <Col lg={3}> </Col>
              <div
                className="col-lg-6 "
                style={{
                  backgroundColor: theme.primary,
                  borderRadius: "0 0 8px 8px",
                  padding: "3px 10px",
                }}>
                <Heading
                  color="white"
                  size={3}>
                  New Offer
                </Heading>
              </div>
            </Row>
            {loading ? (
              <View>
                <img
                  src={LoadingBlack}
                  height={200}
                />
              </View>
            ) : (
              <div className="p-3">
                <Row>
                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Buyer Agent's Name"
                        state={buyerAgentName}
                        setState={setBuyerAgentName}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Buyer Agent's Email"
                        state={buyerAgentEmail}
                        setState={setBuyerAgentEmail}
                        extLabel
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Buyer Agent's Phone Number"
                        state={buyerAgentPhone}
                        setState={setBuyerAgentPhone}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Buyer Name"
                        state={buyerName}
                        setState={setBuyerName}
                        extLabel
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Buyer Email"
                        state={buyerEmail}
                        setState={setBuyerEmail}
                        extLabel
                      />
                      <Checkbox
                        label="Share all offer activity with this buyer"
                        state={shareActivity}
                        setState={setShareActivity}
                      />
                    </Col>
                    <Col
                      lg={6}
                      style={{ textAlign: "right" }}>
                      <Heading
                        size={1}
                        align="left"
                        color="grey">
                        We will not share the email address with anyone. It will
                        help us to keep your account organized and it will allow
                        you to keep your buyer posted on all activity
                      </Heading>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <LargeTextInput
                        placeholder="Cover Letter"
                        state={coverLetter}
                        setState={setCoverLetter}
                      />
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <DropDown
                            label="Financing Type"
                            state={financingType}
                            setState={setFinancingType}
                            options={financingOptions}
                          />
                        </Col>
                        <Col lg={6}>
                          <img
                            src={house}
                            height="200"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Offer Price"
                        state={offerPrice}
                        setState={setOfferPrice}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Deposit (EMD) Percent"
                        state={depositPercent}
                        setState={setDepositPercent}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Deposit (EMD) Amount"
                        state={depositAmount}
                        setState={setDepositAmount}
                        extLabel
                      />
                    </Col>

                    <Col lg={6}>
                      <Row>
                        <Col lg={9}>
                          <Input
                            label="Loan Amount"
                            state={loanAmount}
                            setState={setLoanAmount}
                            extLabel
                          />
                        </Col>
                        <Col lg={3}>
                          <Checkbox
                            label="Buyer is Pre-Qualified"
                            state={preQualified}
                            setState={setPreQualified}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Additional Financing"
                        state={additionalFinancing}
                        setState={setAdditionalFinancing}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Amount"
                        state={financingAmount}
                        setState={setFinancingAmount}
                        extLabel
                      />
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={9}>
                          <Input
                            label="Finance Contingency (Days)"
                            state={financeContingency}
                            setState={setFinanceContingency}
                            extLabel
                          />
                        </Col>
                        <Col lg={3}>
                          <Checkbox
                            label="Waived"
                            state={financeWaived}
                            setState={setFinanceWaived}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={9}>
                          <Input
                            label="Appraisal Contingency (Days)"
                            state={appraisalContingency}
                            setState={setAppraisalContingency}
                            extLabel
                          />
                        </Col>
                        <Col lg={3}>
                          <Checkbox
                            label="Waived"
                            state={appraisalWaived}
                            setState={setAppraisalWaived}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={9}>
                          <Input
                            label="Inspection Contingency (Days)"
                            state={inspectionContingency}
                            setState={setInspectionContingency}
                            extLabel
                          />
                        </Col>
                        <Col lg={3}>
                          <Checkbox
                            label="Waived"
                            state={inspectionWaived}
                            setState={setInspectionWaived}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Closing Date (Days)"
                        state={closingDate}
                        setState={setClosingDate}
                        extLabel
                      />
                    </Col>
                  </Row>
                  <Row className="mx-auto my-3">
                    <Col
                      lg={3}
                      className="mx-auto">
                      <Button onClick={save}>Submit Offer</Button>
                    </Col>
                  </Row>
                </Row>
              </div>
            )}
          </HomeDiv>
        </Col>
      </Row>
    </>
  );
};
