import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Button, Heading, useEloise } from "typed-component-gallery";

interface GenLinkProps {}
const convertShort = (y: string) => {
  let x;
  switch (y) {
    case "docs":
      x = "Initial Documents";
      break;
    case "pay":
      x = "Payments";
      break;
    case "tit":
      x = "Title and Escrow";
      break;
    case "ins":
      x = "Inspections & Disclousures";
      break;
    case "clo":
      x = "Closing Documents";
      break;
    case "dee":
      x = "Deeds & Final Docs";
      break;
    default:
      x = "x";
      break;
  }
  return x;
};

export const ReviewTranny: React.FC<GenLinkProps> = ({}) => {
  const { logic, theme } = useEloise();
  const { id } = useParams();
  const [todos, setTodos] = useState<Array<string>>([]);
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();
  logic.hooks.useAsyncEffect(async () => {
    const test = await logic.fb.docs.getUserDoc("transactions/" + id);
    if (test) {
      setData(test.data);
      if (test.data.todo) {
        setTodos(test.data.todo);
      }
    }
  }, []);
  function formatDollarAmount(amount: number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }

  return (
    <Row>
      <Col
        lg={4}
        className="p-3 mx-auto">
        <Row className="mb-4">
          <Heading
            size={3}
            color="black"
            align="left">
            Todo
          </Heading>
        </Row>
        <div className="mx-4">
          {todos ? (
            todos.map((todo) => (
              <Heading
                key={todo}
                size={2}
                color="black"
                align="left">
                {convertShort(todo)}
              </Heading>
            ))
          ) : (
            <Heading
              size={2}
              color="black"
              align="left">
              All Done!
            </Heading>
          )}
        </div>
      </Col>
      <Col
        lg={6}
        className="p-3 mx-auto">
        <Row className="mb-4">
          <Heading
            size={3}
            color="black"
            align="left">
            Summary
          </Heading>
        </Row>

        <div className="mx-4">
          <Row>
            <Col
              lg={5}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                Address:
              </Heading>
            </Col>
            <Col
              lg={7}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                {data && data.propertyAddress
                  ? String(data.propertyAddress.street)
                  : "Not Set"}
              </Heading>
            </Col>
          </Row>

          <Row>
            <Col
              lg={5}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                Price:
              </Heading>
            </Col>
            <Col
              lg={7}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                {data && data.propertyPrice
                  ? String(formatDollarAmount(data.propertyPrice))
                  : "Not Set"}
              </Heading>
            </Col>
          </Row>
          <Row>
            <Col
              lg={5}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                Role:
              </Heading>
            </Col>
            <Col
              lg={7}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                {data && data.role ? String(data.role) : "Not Set"}
              </Heading>
            </Col>
          </Row>
          <Row>
            <Col
              lg={5}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                Type:
              </Heading>
            </Col>
            <Col
              lg={7}
              className="mx-auto">
              <Heading
                size={2}
                color="black"
                align="left">
                {data && data.type ? String(data.type) : "Not Set"}
              </Heading>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              lg={6}
              className="mx-auto">
              <Button onClick={() => navigate("/transactions")}>
                Return to Transactions
              </Button>
            </Col>
            <Col
              lg={6}
              className="mx-auto">
              <Button onClick={() => navigate("/")}>Return Home</Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
