import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  CalendarComponent,
  DropDown,
  Heading,
  Input,
  LargeTextInput,
  useEloise,
} from "typed-component-gallery";

import { useParams } from "react-router";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Event } from "./event";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  border-radius: 10px;
`;

export function getTimePeriod(start: Date, end: Date) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const optionsDate: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateString = startDate.toLocaleString("en-US", optionsDate);
  const startTimeString = startDate.toLocaleString("en-US", optionsTime);
  const endTimeString = endDate.toLocaleString("en-US", optionsTime);

  return `${dateString} - ${startTimeString} - ${endTimeString}`;
}
export const Schedule: React.FC<{}> = ({}) => {
  const { logic, theme } = useEloise();
  const { id } = useParams();

  const [dates, setDates] = useState<Array<Event>>([]);
  const [newDate, setNewDate] = useState(false);

  const [newDateType, setNewDateType] = useState("");
  const [customType, setCustomType] = useState("");
  const [slot, setSlot] = useState({ start: new Date(), end: new Date() });
  const [notes, setNotes] = useState<string>("");

  logic.hooks.useAsyncEffect(async () => {
    let old = await logic.fb.docs.getUserDoc("transactions/" + id);
    if (old) {
      let oldEs = old.data.events;

      setDates(oldEs);
    }
  }, []);

  const save = async () => {
    let { start, end } = slot;
    let ots: Event = {
      data: {
        start: slot.start,
        end: slot.end,
        title: newDateType,
        notes,
        readable: getTimePeriod(start, end),
        type: newDateType,
      },
      id: uuidv4(),
    };
    let newId = uuidv4();
    let old = await logic.fb.docs.getUserDoc("transactions/" + id);
    if (old) {
      let oldEs = old.data.events;
      if (oldEs) {
        let newEs = [...oldEs, ots];
        let done = await await logic.fb.docs.setUserDoc("transactions/" + id, {
          ...old.data,
          events: newEs,
        });
        setDates(newEs);
      } else {
        let newEs: Event[] = [ots];

        let done = await logic.fb.docs.setUserDoc("transactions/" + id, {
          ...old.data,
          events: newEs,
        });
        setDates(newEs);
      }
    }

    setNewDate(false);
  };

  const handleClose = () => {
    setNewDate(false);
  };

  const handleNew = (x: any) => {
    setSlot(x);
    console.log(x);
    setNewDate(true);
  };

  return (
    <Row>
      {newDate && (
        <ModalOverlay onClick={handleClose}>
          <ModalContent
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              maxWidth: "75%",
              padding: "20px",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "75%",
              minWidth: "400px",
              overflowY: "scroll",
            }}
            onClick={(e: any) => e.stopPropagation()}>
            <Container>
              <Row>
                <Col
                  lg={9}
                  className="mx-auto">
                  <Row className="mb-3">
                    <Heading
                      style={{
                        textDecoration: `underline 2px ${theme.secondary}`,
                      }}
                      size={3}
                      color="black">
                      New Event
                    </Heading>
                  </Row>
                  <Row className="mb-4">
                    <DropDown
                      state={newDateType}
                      setState={setNewDateType}
                      extLabel
                      options={[
                        "Closing Date",
                        "Inspection",
                        "Payment Due",
                        "Viewing",
                        "Custom",
                      ]}
                      label="Type"
                    />
                  </Row>
                  {newDateType === "Custom" && (
                    <Row className="mb-3">
                      <Input
                        rounded
                        state={customType}
                        setState={setCustomType}
                        extLabel
                        label="Custom Type"
                      />
                    </Row>
                  )}

                  <Row className="my-4">
                    {slot && getTimePeriod(slot.start, slot.end)}
                  </Row>
                  <Row>
                    <LargeTextInput
                      state={notes}
                      setState={setNotes}
                      placeholder="Notes"
                    />
                  </Row>

                  {newDateType &&
                    (newDateType !== "custom" || customType !== "") && (
                      <Row>
                        <Button onClick={save}>Save</Button>
                      </Row>
                    )}
                </Col>
              </Row>
            </Container>
          </ModalContent>
        </ModalOverlay>
      )}
      <Heading
        size={2}
        color="black">
        Click a Day to Add an Event
      </Heading>
      {dates && (
        <CalendarComponent
          Events={dates}
          onNew={handleNew}
          onSelect={handleNew}
        />
      )}
    </Row>
  );
};
