import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import {
  Button,
  DropDown,
  Heading,
  Input,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";

export interface DepositProps {}

export const Deposits: React.FC<DepositProps> = ({}) => {
  const { id } = useParams();
  const { logic } = useEloise();

  const docPath = `transactions/${id}`;

  const [depositPayments, setDepositPayments] = logic.fb.hooks.useThrottleField(
    docPath,
    "deposits"
  );
  const addDeposit = () => {
    const id = uuidv4();

    setDepositPayments(
      depositPayments && depositPayments.length
        ? [...depositPayments, { amount: "", id, type: "$", date: new Date() }]
        : [{ amount: "", id, type: "$", date: new Date() }]
    );
  };

  return (
    <Col lg={4}>
      <Heading
        size={3}
        style={{ fontWeight: 400 }}
        align="left">
        Deposit Payments
      </Heading>
      {depositPayments &&
        depositPayments.map(
          (
            deposit: {
              id: React.Key | null | undefined;
              amount: any;
              type: string | undefined;
            },
            index: number
          ) => (
            <Row key={deposit.id}>
              <Col
                lg={12}
                className="row">
                <Col lg={6}>
                  <Input
                    style={{ border: `1px solid grey` }}
                    rounded
                    label={`Deposit ${index + 1}`}
                    state={deposit.amount}
                    setState={(amount: any) => {
                      const newDeposits = [...depositPayments];
                      newDeposits[index].amount = amount;
                      setDepositPayments(newDeposits);
                    }}
                    extLabel
                  />
                </Col>
                <Col lg={3}>
                  <DropDown
                    label={deposit.type}
                    state={deposit.type}
                    setState={(type: any) => {
                      const newDeposits = [...depositPayments];
                      newDeposits[index].type = type;
                      setDepositPayments(newDeposits);
                    }}
                    options={["%", "$"]}
                  />
                </Col>
              </Col>
            </Row>
          )
        )}
      <Row className="mt-3">
        <Col lg={6}>
          <Button
            color="primary"
            onClick={addDeposit}>
            Add Deposit
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
