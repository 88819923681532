import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import {
  Button,
  DBTable,
  Heading,
  LargeTextInput,
  useEloise,
} from "typed-component-gallery";

import { uuidv4 } from "@firebase/util";
import houseSpread from "../../assets/housespread.png";
import CalendarComponent from "../widgets/calendar";
import { Event } from "../widgets/event";

const HomeDiv = styled.div`
  margin: 10px;
  border: 1px solid grey;
  border-radius: 8px;
`;
const NewEvent: React.FC<{ select: (data: any) => void }> = ({ select }) => {
  return (
    <div style={{ minHeight: "600px" }}>
      <Heading
        style={{ textDecoration: "2px solid underline" }}
        size={3}>
        New Event
      </Heading>
    </div>
  );
};
const EmptyMessage = () => {
  const { theme } = useEloise();
  const navigate = useNavigate();
  const { id } = useParams();
  const newId = uuidv4();
  return (
    <Row className="mt-1">
      <Heading size={3}>You don't have any offers yet.</Heading>
      <Heading size={2}>Click the button to add one manually</Heading>
      <Row>
        <Col
          lg={6}
          className="mx-auto">
          <Button onClick={() => navigate("create-offer/" + id + "/" + newId)}>
            New
          </Button>
        </Col>
      </Row>
    </Row>
  );
};
export const ListingDetails: React.FC<{}> = () => {
  const { id } = useParams();
  const docPath = `listings/${id}`;
  const [listing, setListing] = useState<any>();
  const navigate = useNavigate();
  const { logic, theme } = useEloise();
  const [notes, setNotes] = logic.fb.hooks.useThrottleField(docPath, "notes");

  const [dates, setDates] = useState<Array<Event>>([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleNew = (x: any) => {
    console.log(x);
    setShowModal(true); // Show modal when handleNew is called
  };

  logic.hooks.useAsyncEffect(async () => {
    // Fetch the listing information from the database and set it to the state
    // Here is a placeholder, you should replace this with your database fetching logic.
    const fetchedListing = await logic.fb.docs.getUserDoc(docPath);

    setListing(fetchedListing.data);
  }, []);

  const handleClose = () => {
    setShowModal(false); // Function to hide modal
  };

  const chooseList = (data: any) => {
    console.log(data);
  };

  return (
    <Row className="mx-auto">
      <Modal
        style={{ minWidth: "75vw" }}
        show={showModal}
        onHide={handleClose}>
        <Modal.Body>
          <NewEvent select={chooseList} />
          <Row className="g-1">
            <Col lg={4}></Col>
            <Col lg={3}>
              <Button
                color="secondary"
                onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col lg={5}>
              <Button onClick={handleClose}>Save Changes</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Col lg={12}>
        <HomeDiv>
          {listing ? (
            <>
              <Row className="mx-auto mb-4">
                <Col lg={3}></Col>
                <div
                  className="col-lg-6"
                  style={{
                    backgroundColor: theme ? theme.primary : "gray",
                    borderRadius: "0 0 8px 8px",
                    padding: "3px 10px",
                  }}>
                  <Heading
                    color="white"
                    size={3}>
                    Listing Details
                  </Heading>
                </div>
              </Row>

              <Row className="m-5">
                <Col lg={8}>
                  <Row className="mb-3">
                    <Col lg={3}>
                      <Heading
                        size={3}
                        align="left"
                        color="black">
                        Info
                      </Heading>
                    </Col>
                    <Col lg={2}>
                      <Button
                        color="secondary"
                        style={{ padding: " 3px", border: "none" }}
                        onClick={() =>
                          navigate("/listings/create-listing/" + id)
                        }>
                        Edit
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <p>MLS Number: {listing.MLSNumber}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={5}>
                      <p>Address: {listing.addy.street}</p>
                    </Col>
                    <Col lg={2}>
                      <p>Unit: {listing.unit}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={5}>
                      <p>Price: {listing.price}</p>
                    </Col>
                    <Col lg={5}>
                      <p>Neighborhood: {listing.neighborhood}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={5}>
                      <p>Property Type: {listing.propertyType}</p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={4}
                  className="text-center">
                  <Row className="mb-3">
                    <Heading
                      size={3}
                      align="left"
                      color="black">
                      Pictures
                    </Heading>
                  </Row>

                  <img
                    src={houseSpread}
                    height="200"
                    alt="House"
                  />
                </Col>
              </Row>

              <Row className="m-5">
                <Col lg={6}>
                  <Row className="mb-3">
                    <Heading
                      size={3}
                      align="left"
                      color="black">
                      Notes
                    </Heading>
                  </Row>
                  <Row>
                    <LargeTextInput
                      state={notes}
                      setState={setNotes}
                      placeholder="Notes"
                    />
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="mb-3">
                    <Heading
                      size={3}
                      align="left"
                      color="black">
                      Offers
                    </Heading>
                  </Row>
                  <Row>
                    <DBTable
                      Empty={EmptyMessage}
                      path={`${docPath}/offers`}
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="m-5">
                <Heading
                  size={3}
                  align="left"
                  color="black">
                  Events
                </Heading>
              </Row>
              <Row className="m-5">
                <CalendarComponent
                  Events={dates}
                  onNew={handleNew}
                  onSelect={handleNew}
                />
              </Row>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </HomeDiv>
      </Col>
    </Row>
  );
};
