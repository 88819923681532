import { mdiAccount } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  AppIcon,
  Contact,
  HSLAColor,
  Heading,
  Theme,
  View,
  useEloise,
} from "typed-component-gallery";
import { HomeDiv } from "./transactions";

const PersonRow = styled.div`
  height: 75px;
  border-radius: 40px;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
`;

const Person: React.FC<{ contact: Contact; color: string }> = ({
  contact,
  color,
}) => {
  const { theme, siteConfig } = useEloise();

  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    setIcon(itu());
  }, []);

  const itu = () => {
    let test = siteConfig.peopleConfig.find(
      (x: { title: any }) => x.title === contact.type
    );
    if (test) return test.icon;
    return mdiAccount;
  };

  return (
    <Row>
      <PersonRow className="mx-auto row">
        <Col lg={1}>
          <div
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              backgroundColor: color,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}>
            {contact.initials}
          </div>
        </Col>
        <Col lg={3}>
          <Heading size={2}>{contact.first + " " + contact.last}</Heading>
        </Col>
        <Col lg={6}>
          <label htmlFor="email">{contact.email}</label>
        </Col>
        <Col lg={1}>
          <AppIcon
            color={color}
            icon={icon}
            size={2.5}
            title={contact.type}
            onClick={() => {}}
          />
        </Col>
      </PersonRow>
    </Row>
  );
};

const assignColorToTypes = (
  types: Set<string>,
  theme: Theme
): Record<string, HSLAColor> => {
  const colors = [
    theme.primary,
    theme.secondary,
    theme.white,
    theme.accent,
    theme.accent2,
    theme.accent3,
  ];
  let colorIndex = 0;

  const typeColorMapping: Record<string, HSLAColor> = {};

  for (const type of types) {
    typeColorMapping[type] = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length; // Increment the index, or reset it back to 0 if we've gone through all colors
  }

  return typeColorMapping;
};
const getUniqueTypes = (contacts: Contact[]): Set<string> => {
  const types = contacts.map((contact) => contact.type);
  return new Set(types);
};

export const People: React.FC = () => {
  const { logic, theme, siteConfig } = useEloise();

  const [colorMap, setColorMap] = useState<Record<string, HSLAColor>>();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<Set<string>>(new Set());

  const [contacts, setContacts] = useState<Array<any>>([]);

  const renderTypeButtons = () => {
    const types = getUniqueTypes(contacts);
    return Array.from(types).map((type) => {
      const isSelected = selectedTypes.has(type);
      const icon =
        siteConfig.peopleConfig.find((x: { title: string }) => x.title === type)
          ?.icon || mdiAccount;
      return (
        <Col lg={2}>
          <button
            style={{
              borderRadius: "3px",
              backgroundColor: isSelected
                ? colorMap && colorMap[type]
                : "white",
              borderWidth: "1px",
            }}
            onClick={() => {
              if (isSelected) {
                setSelectedTypes((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(type);
                  return newSet;
                });
              } else {
                setSelectedTypes((prev) => new Set(prev).add(type));
              }
            }}>
            <Row>
              <Col lg={4}>
                <AppIcon
                  color={isSelected ? "white" : colorMap && colorMap[type]}
                  icon={icon}
                  size={2.5}
                  title={type}
                  onClick={() => {}}
                />
              </Col>
              <Col lg={8}>
                <View>
                  <Heading color={isSelected ? "white" : "black"}>
                    {type}
                  </Heading>
                </View>
              </Col>
            </Row>
          </button>
        </Col>
      );
    });
  };

  useEffect(() => {
    if (contacts) {
      let types = getUniqueTypes(contacts);
      let newMap = assignColorToTypes(types, theme);

      setColorMap(newMap);
    }
  }, [contacts]);

  logic.hooks.useAsyncEffect(async () => {
    console.log("effect async");

    let test = await logic.fb.contacts.getUserContacts();

    if (test) {
      console.log(test);
      setContacts(test);
    }
  }, []);
  const renderSearchBar = () => {
    return (
      <input
        type="text"
        placeholder="Search contacts..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          width: "100%",
          padding: "12px 20px",
          margin: "8px 0",
          boxSizing: "border-box",
        }}
      />
    );
  };
  const fuzzySearch = (str: string, searchQuery: string) => {
    const query = searchQuery.toLowerCase();
    let index = 0;
    for (let char of str.toLowerCase()) {
      if (char === query[index]) {
        index++;
      }
      if (index === query.length) {
        return true;
      }
    }
    return false;
  };

  return (
    <Container>
      <Row>
        <Col
          lg={11}
          className="mx-auto">
          <HomeDiv style={{ minHeight: "78vh" }}>
            <Row className="mx-auto">
              <Col lg={6}>
                <Heading
                  color="black"
                  align="left"
                  size={3}>
                  Contacts
                </Heading>
              </Col>
              <Col lg={6}>{renderSearchBar()}</Col>
            </Row>
            <Row className="m-3">{renderTypeButtons()}</Row>

            <Row className="m-3">
              {contacts && contacts.length > 0 ? (
                contacts
                  .map((contact) => ({
                    ...contact,
                    fullName: `${contact.first} ${contact.last}`,
                  }))
                  .filter((contact) => {
                    for (let field in contact) {
                      if (
                        typeof contact[field] === "string" &&
                        fuzzySearch(contact[field], searchQuery)
                      ) {
                        return true;
                      }
                    }
                    return selectedTypes.has(contact.type);
                  })
                  .map((contact) => (
                    <Person
                      contact={contact}
                      color={colorMap ? colorMap[contact.type] : theme.primary}
                    />
                  ))
              ) : (
                <Row className="mt-5">
                  <Heading size={3}> You don't have any contacts yet</Heading>
                  <Heading size={2}>
                    As you make connections they will show up here
                  </Heading>
                </Row>
              )}
            </Row>
          </HomeDiv>
        </Col>
      </Row>
    </Container>
  );
};
