import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  Button,
  CollectionRender,
  EloiseWidget,
  Heading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";

export const HomeDiv = styled.div`
  position: relative;
  margin: 10px;
  padding: 0 10px;
  border: 2px solid grey;
  border-radius: 8px;

  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }

  @media (max-width: 1000px) {
    height: auto !important;
  }
`;

export const Transactions: React.FC<{}> = ({}) => {
  const { theme, logic, eloiseContent } = useEloise();

  const navigate = useNavigate();

  logic.hooks.useAsyncEffect(async () => {}, []);

  return (
    <>
      <EloiseWidget
        eloiseIntel={{ title: "Listing Page", desc: "test desc listing page" }}>
        <Row className="mx-auto">
          <Col lg={12}>
            <HomeDiv>
              <Row>
                <div
                  className="col-lg-3 "
                  style={{
                    backgroundColor: theme.primary,
                    borderRadius: "8px 0 8px 0",
                    padding: "13px 15px",
                    marginTop: "0",
                  }}>
                  <Heading
                    color="white"
                    size={3}>
                    My Transactions
                  </Heading>
                </div>

                <Col lg={6}></Col>
                <Col
                  lg={3}
                  className="mt-2">
                  <Button
                    onClick={() => navigate("./create-transaction/" + uuidv4())}
                    color="secondary">
                    New Transaction
                  </Button>
                </Col>
              </Row>
              <Row className="mx-auto my-5">
                <CollectionRender
                  path="transactions"
                  views={["calendar", "table"]}
                  tableProps={{
                    open: true,
                    link: "/transactions/create-transaction/",
                    path: "transactions",
                    fields: "manual",
                    manualDefault: ["title", "propertyPrice", "type"],
                  }}
                />
              </Row>
            </HomeDiv>
          </Col>
        </Row>
      </EloiseWidget>
    </>
  );
};
