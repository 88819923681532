import {
  mdiAccountGroupOutline,
  mdiAccountTie,
  mdiClipboardListOutline,
  mdiEmailFastOutline,
  mdiHome,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { Button, Heading, Input, useEloise } from "typed-component-gallery";
import house from "../../assets/houseLarge.png";
import IconList from "./iconList";
import { HomeDiv } from "./transactions";

const Step1: React.FC<{ done: () => void }> = ({ done }) => {
  const { theme, logic } = useEloise();
  const [init, setInit] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const provider = new GoogleAuthProvider();
  const [user, loading, error] = useAuthState(logic.fb.auth);

  const signUpWithGoogle = async () => {
    try {
      const result = await signInWithPopup(logic.fb.auth, provider);
      console.log(result);
      if (result.user.email) {
        setEmail(result.user.email); // Set email from Google response
        // setPassword(result.user.password);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  function testPassword(password: string): boolean {
    // Password needs to have at least 8 characters, 1 capital letter and 1 special character
    const regex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    return regex.test(password);
  }
  function testEmail(email: string): boolean {
    // Simple email pattern: any number of characters, then '@', then any number of characters, then '.', then any number of characters.
    // This is a very simplistic check and won't cover all valid email cases, but it can be useful for basic email format checking.
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  }

  function nextTest(): string {
    if (!email || !password || !passwordC) return "no";
    if (password !== passwordC) return "Passwords do not match.";
    if (!testEmail(email)) return "Invalid email address.";
    if (!testPassword(password))
      return "Password must be at least 8 characters long, include a capital letter and a special character.";
    return ""; // All tests pass
  }

  let validationMessage = nextTest();

  const [isVerified, setIsVerified] = useState(false);

  const createUser = async () => {
    try {
      const result = await createUserWithEmailAndPassword(
        logic.fb.auth,
        email,
        password
      );
      await logic.fb.docs.setUser({
        email,
        apps: ["realEstate"],
        perms: "trial",
        trialExpire: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
      });

      // User created successfully, now we send the verification email
      await sendEmailVerification(result.user);
      setInit(true);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(logic.fb.auth, async (user) => {
      if (user) {
        // reload the user object to update the emailVerified property
        await user.reload();
        setIsVerified(user.emailVerified);
      }
    });
    // Clean up the onAuthStateChanged observer
    return () => unsubscribe();
  }, [logic.fb.auth]);

  // The 'next' button, upon click it will check if form is valid and then create user and send verification email.
  const NextButton = () => {
    let validationMessage = nextTest();
    if (validationMessage === "") {
      createUser();
    }
  };

  const CheckEmail = async () => {
    if (user) {
      if (user.emailVerified) {
        done();
      } else {
        await user.reload();
        setIsVerified(user.emailVerified);
      }
    }
  };

  return (
    <Row className="m-3">
      {init ? (
        <>
          <Row className="mt-5">
            <Col
              lg={6}
              className="mx-auto text-center">
              <Icon
                style={{ color: theme.primary }}
                path={mdiEmailFastOutline}
                size={5}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Heading size={3}>
              I sent you a verifcation email. Click on the link to continue
            </Heading>
          </Row>
          <Row className="mt-5">
            <Button
              type="submit"
              rounded
              onClick={CheckEmail}>
              Next
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Heading
            style={{ textDecoration: `2px underline black` }}
            size={3}>
            Welcome! Let's Get Started.
          </Heading>
          <Col lg={12}>
            <Input
              extLabel
              label="Email"
              state={email}
              setState={setEmail}
            />
          </Col>
          <Col lg={12}>
            <Input
              extLabel
              label="Password"
              type="password"
              state={password}
              setState={setPassword}
            />
          </Col>
          <Col lg={12}>
            <Input
              extLabel
              label="Confirm Password"
              type="password"
              state={passwordC}
              setState={setPasswordC}
            />
          </Col>
          {validationMessage === "no" ? (
            <></>
          ) : validationMessage === "" ? (
            <Col
              lg={12}
              className="mt-3">
              <Button
                type="submit"
                rounded
                onClick={NextButton}>
                Next
              </Button>
            </Col>
          ) : (
            <Col
              lg={12}
              className="mt-3">
              <p style={{ color: "red" }}>{validationMessage}</p>
            </Col>
          )}
          <Col
            lg={12}
            className="mt-3">
            <Button
              rounded
              onClick={signUpWithGoogle}>
              Sign Up with Google
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};
export const Signup = () => {
  const { theme, logic } = useEloise();

  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [preName, setPreName] = useState("");
  const [role, setRole] = useState("");

  const [user, loading, error] = useAuthState(logic.fb.auth);
  const [stepTwo, setStepTwo] = useState(true);

  const step1Done = () => {
    setStepTwo(true);
  };

  const step2Done = () => {
    let test = true;
    if (firstName === "") test = false;
    if (lastName === "") test = false;
    if (phone === "") test = false;
    if (!role) test = false;

    return test;
  };

  const final = async () => {
    let twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
    await logic.fb.docs.setUser({
      first: firstName,
      last: lastName,
      phone,
      preName,
    });
    await logic.fb.docs.setUserDoc("", {
      role,
      perm: "trial",
      trialDate: twoWeeksFromNow,
    });
    navigate("/");
  };

  return (
    <div>
      <Row className="mt-2">
        {!stepTwo && (
          <Col
            lg={5}
            className="mx-auto">
            <img
              src={house}
              height={"400"}
            />
          </Col>
        )}
        <Col
          style={{ transition: "all ease 2s" }}
          lg={stepTwo ? 12 : 5}
          className="mx-auto">
          <HomeDiv>
            {!stepTwo ? (
              <Step1 done={step1Done} />
            ) : (
              <Row className="my-3">
                <Col
                  lg={4}
                  className="mx-auto">
                  <Row>
                    <Heading size={3}>Let's get some more info</Heading>
                  </Row>

                  <Row className="mt-5">
                    <Col lg={12}>
                      <Input
                        extLabel
                        label="First Name"
                        state={firstName}
                        setState={setFirstName}
                      />
                    </Col>
                    <Col lg={12}>
                      <Input
                        extLabel
                        label="Last Name"
                        state={lastName}
                        setState={setLastName}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <Input
                        extLabel
                        label="Prefered Name"
                        state={preName}
                        setState={setPreName}
                      />
                    </Col>
                    <Col lg={12}>
                      <Input
                        extLabel
                        label="Phone"
                        state={phone}
                        setState={setPhone}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Col
                    lg={12}
                    className="">
                    <Heading size={3}>Which best describes you?</Heading>
                  </Col>
                  <Col
                    lg={12}
                    className="mt-3">
                    <IconList
                      selected={role}
                      setSelected={setRole}
                      items={[
                        {
                          text: "Buyer/Seller",
                          icon: mdiHome,
                        },
                        {
                          text: "Agent",
                          icon: mdiAccountTie,
                        },
                        {
                          text: "Property Manager",
                          icon: mdiClipboardListOutline,
                        },
                        {
                          text: "Agent Manager",
                          icon: mdiAccountGroupOutline,
                        },
                      ]}
                    />
                  </Col>
                </Col>
                {step2Done() && (
                  <Col
                    lg={3}
                    className="mt-3 mx-auto">
                    <Button
                      onClick={final}
                      rounded>
                      Done
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </HomeDiv>
        </Col>
      </Row>
    </div>
  );
};
