import React from "react";
import { useNavigate } from "react-router";
import { useEloise } from "typed-component-gallery";

export const Admin: React.FC = () => {
  const { logic } = useEloise();
  const navigate = useNavigate();

  logic.hooks.useAsyncEffect(async () => {
    let testUser = await logic.fb.docs.getUser();

    if (testUser.perm !== "admin") {
      navigate("/");
    }
  }, []);

  return <></>;
};
