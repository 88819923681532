import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import {
  Button,
  DropDown,
  FileUpload,
  Heading,
  Input,
  LargeTextInput,
  View,
  useEloise,
} from "typed-component-gallery";
import house from "../../assets/house.png";
import houseSpread from "../../assets/housespread.png";

import LoadingBlack from "../../assets/loadingBlack.svg";

const HomeDiv = styled.div`
  margin: 10px;
  border: 1px solid grey;
  border-radius: 8px;

  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }
`;

export const ListingCreate: React.FC<{}> = ({}) => {
  const { logic } = useEloise();
  const { id } = useParams();

  const docPath = `listings/${id}`;

  const [mlsNum, setMlsNum] = logic.fb.hooks.useThrottleField(
    docPath,
    "MLSNumber"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [shareFirst, setShareFirst] = logic.fb.hooks.useThrottleField(
    docPath,
    "shareFirst"
  );
  const [shareLast, setShareLast] = logic.fb.hooks.useThrottleField(
    docPath,
    "shareLast"
  );
  const [shareEmail, setShareEmail] = logic.fb.hooks.useThrottleField(
    docPath,
    "shareEmail"
  );
  const [sharePhone, setSharePhone] = logic.fb.hooks.useThrottleField(
    docPath,
    "sharePhone"
  );
  const [propType, setPropType] = logic.fb.hooks.useThrottleField(
    docPath,
    "propertyType"
  );
  const propTypes = [
    "Single family home",
    "Apartment",
    "Townhouse",
    "Condo",
    "Co-op",
    "Loft",
    "TIC",
    "Villa",
    "Studio",
    "Maisonette",
    "Commercial",
    "Land",
    "Other",
    "2-4 Unit",
    "Farm",
    "Manufactured home",
    "Mobile home",
    "Preconstruction",
  ];
  const [addy, setAddy] = logic.fb.hooks.useThrottleField(docPath, "addy");
  const [price, setPrice] = logic.fb.hooks.useThrottleField(docPath, "price");
  const [unit, setUnit] = logic.fb.hooks.useThrottleField(docPath, "unit");
  const [hood, setHood] = logic.fb.hooks.useThrottleField(
    docPath,
    "neighborhood"
  );
  const [size, setSize] = logic.fb.hooks.useThrottleField(docPath, "size");
  const [bedrooms, setBedrooms] = logic.fb.hooks.useThrottleField(
    docPath,
    "bedrooms"
  );
  const [bathrooms, setBathrooms] = logic.fb.hooks.useThrottleField(
    docPath,
    "bathrooms"
  );
  const [yearBuilt, setYearBuilt] = logic.fb.hooks.useThrottleField(
    docPath,
    "yearBuilt"
  );
  const [floors, setFloors] = logic.fb.hooks.useThrottleField(
    docPath,
    "floors"
  );
  const [description, setDescription] = logic.fb.hooks.useThrottleField(
    docPath,
    "description"
  );
  const [agentRemarks, setAgentRemarks] = logic.fb.hooks.useThrottleField(
    docPath,
    "agentRemarks"
  );
  const [autoFillVal, setAutoFillVal] = useState<string>("");

  const navigate = useNavigate();

  const setAllStates = (propertyObject: any) => {
    setPropType(propertyObject.propType || "");
    setAddy(propertyObject.addy || "");
    setPrice(propertyObject.price || "");
    setUnit(propertyObject.unit || "");
    setHood(propertyObject.hood || "");
    setSize(propertyObject.size || "");
    setBedrooms(propertyObject.bedrooms || "");
    setBathrooms(propertyObject.bathrooms || "");
    setYearBuilt(propertyObject.yearBuilt || "");
    setFloors(propertyObject.floors || "");
    setDescription(propertyObject.description || "");
    setAgentRemarks(propertyObject.agentRemarks || "");
    setMlsNum(propertyObject.mlsNumber || "");
  };

  const save = async () => {
    navigate("./listings");
  };

  function parsePropertyString(input: string) {
    const result: Record<string, string> = {};
    const properties = input.split(",").map((prop) => prop.trim());

    for (const prop of properties) {
      const [key, value] = prop.split(":").map((item) => item.trim());
      const cleanValue = value
        ? value.replace(/(^"|"$)/g, "").replace(/'/g, "")
        : "";
      result[key] = cleanValue;
    }

    return result;
  }

  const autoFill = async () => {
    if (autoFillVal) {
      setLoading(true);
      const response = await logic.zillowParse(autoFillVal);

      if (response) {
        let test = parsePropertyString(response);
        setAllStates(test);
        setLoading(false);
      }
    }
  };

  const { theme } = useEloise();

  return (
    <>
      <Row className="mx-auto">
        <Col lg={12}>
          <HomeDiv>
            {loading ? (
              <View>
                <img
                  src={LoadingBlack}
                  height={200}
                />
              </View>
            ) : (
              <>
                <Row className="mx-auto  mb-4">
                  <Col lg={3}> </Col>
                  <div
                    className="col-lg-6 "
                    style={{
                      backgroundColor: theme.primary,
                      borderRadius: "0 0 8px 8px",
                      padding: "3px 10px",
                    }}>
                    <Heading
                      color="white"
                      size={3}>
                      Listing
                    </Heading>
                  </div>
                </Row>

                <Row className="m-5">
                  <Row>
                    <Col lg={8}>
                      <Row>
                        <Col lg={8}>
                          <LargeTextInput
                            placeholder="Auto Fill: Paste text about your listing from another source"
                            state={autoFillVal}
                            setState={setAutoFillVal}
                          />
                        </Col>
                        <Col lg={4}>
                          <Button onClick={autoFill}>Fill</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Col lg={8}>
                    <Row className="mb-3">
                      <Heading
                        size={3}
                        align="left"
                        color="black">
                        Info
                      </Heading>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <Input
                          label="MLS Number"
                          state={mlsNum}
                          setState={setMlsNum}
                          extLabel
                        />
                      </Col>
                      <Col lg={2}>
                        <Button>Verify</Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={8}>
                        <Input
                          label="Address"
                          addy
                          state={addy}
                          setState={setAddy}
                          extLabel
                        />
                      </Col>
                      <Col lg={2}>
                        <Input
                          label="Unit"
                          state={unit}
                          setState={setUnit}
                          extLabel
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={5}>
                        <Input
                          label="Price"
                          state={price}
                          setState={setPrice}
                          extLabel
                        />
                      </Col>
                      <Col lg={5}>
                        <Input
                          label="Neighborhood"
                          state={hood}
                          setState={setHood}
                          extLabel
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={5}>
                        <DropDown
                          color="black"
                          state={propType}
                          setState={setPropType}
                          options={propTypes}
                          label="Property Type"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={4}
                    className="text-center">
                    <Row className="mb-3">
                      <Heading
                        size={3}
                        align="left"
                        color="black">
                        Add Pictures
                      </Heading>
                    </Row>

                    <FileUpload
                      customIcon={
                        <img
                          src={houseSpread}
                          height="200"
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row className="m-5">
                  <Col lg={6}>
                    <Row className="mb-3">
                      <Heading
                        size={3}
                        align="left"
                        color="black">
                        Details
                      </Heading>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Input
                          label="Size"
                          state={size}
                          setState={setSize}
                          extLabel
                        />
                      </Col>
                      <Col lg={5}>
                        <Input
                          label="Bedrooms"
                          state={bedrooms}
                          setState={setBedrooms}
                          extLabel
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Input
                          label="Bathrooms"
                          state={bathrooms}
                          setState={setBathrooms}
                          extLabel
                        />
                      </Col>
                      <Col lg={5}>
                        <Input
                          label="Year Built"
                          state={yearBuilt}
                          setState={setYearBuilt}
                          extLabel
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Input
                          label="Floors"
                          state={floors}
                          setState={setFloors}
                          extLabel
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10}>
                        <LargeTextInput
                          state={description}
                          setState={setDescription}
                          placeholder="Description of Property"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10}>
                        <LargeTextInput
                          state={agentRemarks}
                          setState={setAgentRemarks}
                          placeholder="Agent Remarks"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    className="text-center">
                    <Row className="mb-3">
                      <Heading
                        size={3}
                        align="left"
                        color="black">
                        Share Offers
                      </Heading>
                      <Heading
                        size={1}
                        align="left"
                        color="grey">
                        The seller will receive notifications when offers come
                        in
                      </Heading>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Input
                          label="First Name"
                          state={shareFirst}
                          setState={setShareFirst}
                          extLabel
                        />
                      </Col>
                      <Col lg={5}>
                        <Input
                          label="Last Name"
                          state={shareLast}
                          setState={setShareLast}
                          extLabel
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Input
                          label="Email"
                          state={shareEmail}
                          setState={setShareEmail}
                          extLabel
                        />
                      </Col>
                      <Col lg={5}>
                        <Input
                          label="Phone"
                          state={sharePhone}
                          setState={setSharePhone}
                          extLabel
                        />
                      </Col>
                    </Row>
                    <Row className="mx-auto">
                      <Col
                        lg={12}
                        style={{
                          textAlign: "right",
                          marginTop: "11vh",
                          marginRight: "10px",
                        }}>
                        <img
                          src={house}
                          height="300"
                          width="300"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mx-auto my-3">
                  <Col
                    lg={3}
                    className="mx-auto">
                    <Button onClick={() => navigate("/listings")}>Done</Button>
                  </Col>
                </Row>
              </>
            )}
          </HomeDiv>
        </Col>
      </Row>
    </>
  );
};
