import { Other, ReportBug } from "typed-component-gallery";
import {
  Account,
  Admin,
  Calendar,
  CreateTransaction,
  Home,
  Listing,
  ListingCreate,
  ListingDetails,
  OfferCreate,
  Offers,
  People,
  Signup,
  Transactions,
  UploadLink,
} from "../components";

export const pages = [
  {
    name: "/",
    component: <Home />,
    intel: {
      title: "Home Page",
      desc: "Landing page demoing features",
    },
  },
  {
    name: "admin",
    component: <Admin />,
    hidden: true,
    intel: {
      title: "Admin Page",
      desc: "Protected Page for administration",
    },
  },
  {
    name: "Listings",
    component: <Listing />,
    intel: {
      title: "Listings Page",
      desc: "Page Displaying Users listings",
    },
  },

  {
    name: "Listing Create",
    component: <ListingCreate />,
    hidden: true,
    url: "/Listings/create-listing/:id",
    intel: {
      title: "Listings Create Page",
      desc: "Page that allows user to create a new listing",
    },
  },
  {
    name: "Listing Details",
    component: <ListingDetails />,
    hidden: true,
    url: "/Listings/listing-details/:id",
    intel: {
      title: "Listings Details Page",
      desc: "Page that allows user to veiw a listing",
    },
  },
  {
    name: "Signup",
    component: <Signup />,
    noAuth: true,
    hidden: true,
    intel: {
      title: " Sign Up",
      desc: "Page that allows user to create a new listing",
    },
  },
  {
    name: "Offers",
    component: <Offers />,
    intel: {
      title: "Offers",
      desc: "Page that allows view a list of their current offers",
    },
  },
  {
    name: "Offer Create",
    component: <OfferCreate />,
    hidden: true,
    url: "/create-offer/:listingId/:offerId",
    intel: {
      title: "Offer-Create",
      desc: "Page that allows user to create a new offer",
    },
  },
  {
    name: "Transactions",
    component: <Transactions />,
    intel: {
      title: "Transactions",
      desc: "Dashboard for user transactions",
    },
  },
  {
    name: "New Transaction",
    component: <CreateTransaction />,
    hidden: true,
    url: "/transactions/create-transaction/:id",
    intel: {
      title: "Transaction Create",
      desc: "Form for building new Transaction",
    },
  },
  {
    name: "Error Other",
    component: <Other />,
    hidden: true,
    url: "other",
  },
  {
    name: "Account",
    component: <Account />,
    hidden: true,
    url: "Account",
    intel: {
      title: "Settings",
      desc: "Lets the user change site and account settings",
    },
  },
  {
    name: "Report Bug",
    component: <ReportBug />,
    hidden: true,
    url: "report-bug",
    intel: {
      title: "Report Bug",
      desc: "Allows user to report bug to dev team",
    },
  },
  {
    name: "Upload File",
    component: <UploadLink />,
    hidden: true,
    url: "upload-file/:id/:docId/:userId",
    noAuth: true,
    intel: {
      title: "Link Upload",
      desc: "Page for unauthenticated vistors to upload a file",
    },
  },
  {
    name: "People",
    component: <People />,
    intel: {
      title: "Contacts",
      desc: "Dashboard for user contacts",
    },
  },
  {
    name: "Schedule",
    component: <Calendar />,
    intel: {
      title: "Calendar",
      desc: "Dashboard for user events in a calendar view",
    },
  },
];
