import { Theme } from "typed-component-gallery";


export const themeConfig: Theme = {
    primary: 'hsla(169, 18%, 52%, 1)',
    secondary: 'hsla(9, 74%, 67%, 1)',
    white: 'hsla(90, 60%, 96%, 1)',
    font: 'roboto',
    fontSize: '1.25px',
    borderRadius: "3px",
    border: '1px solid grey',
    accent: 'hsla(278, 21%, 28%, 1)',
    accent2: 'hsla(40, 100%, 67%, 1)',
    accent3: 'hsla(40, 100%, 67%, 1)',
    mode: 'light',
    input:{
      border:true,
      rounded:true
    }
  };
  