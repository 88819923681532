import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router";
import { FileTable, Heading, useEloise } from "typed-component-gallery";
import { HomeDiv } from "./transactions";

interface GenLinkProps {}

export const UploadLink: React.FC<GenLinkProps> = ({}) => {
  const { logic, siteConfig } = useEloise();

  const { id, docId, userId } = useParams();
  const [message, setMessage] = useState<string>();
  const [user, loading, error] = useAuthState(logic.fb.auth);

  logic.hooks.useAsyncEffect(async () => {
    if (id && docId) {
      let test = await logic.fb.storage.getUploadLink(id, docId);

      console.log(test);
    }
  }, []);

  return (
    <HomeDiv>
      <Row>
        <Heading
          size={4}
          color="black">{`Please upload documents  ${
          message ? `for ${message}` : ""
        } below`}</Heading>
      </Row>

      <Row>
        {!user && (
          <Col
            lg={6}
            className="mt-4">
            <Row>
              <Heading
                color="black"
                size={3}>
                Sign Up Today!
              </Heading>
            </Row>
          </Col>
        )}

        <Col
          lg={6}
          className="mx-auto mt-4">
          <FileTable
            otherUser={userId}
            folderPath={id && docId ? `transactions/${id}/${docId}` : " "}
            actions={[]}
          />
        </Col>
      </Row>
    </HomeDiv>
  );
};
