import { mdiAccount, mdiAccountHardHat, mdiAccountTie } from "@mdi/js";
import { SiteConfig } from "typed-component-gallery";
import logo from "../assets/logo.png";
import { pages } from "./pages";

export const site: SiteConfig = {
  api: "https://us-central1-eloiselife-c5cf6.cloudfunctions.net/text",
  // api: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/text",
  id: "realEstate",
  name: "Unnamed Real Estate App",
  logo: logo,
  inverseLogo: logo,
  sideWidget: [],
  pages,
  headerTrans: false,
  eloiseConfig: {
    endPoint: "https://us-central1-eloiselife-c5cf6.cloudfunctions.net/chat",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there! I'm Eloise your virtual assistant. Let me know if you need anything  ",
  },
  peopleConfig: [
    { title: "Agent", icon: mdiAccountTie },
    { title: "Contractor", icon: mdiAccountHardHat },
    { title: "Buyer/Seller", icon: mdiAccount },
  ],
};
