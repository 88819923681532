import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import {
  DropDown,
  EloiseWidget,
  Heading,
  useEloise,
} from "typed-component-gallery";
import {
  BasicInfo,
  Deposits,
  Participants,
  ReviewTranny,
  Schedule,
  Timeline,
  TrannyRow,
  UploadOps,
} from "../";
import { HomeDiv } from "./transactions";

const options = [
  "Buyer",
  "Seller",
  "Seller's agent",
  "Buyer's agent",
  "Seller transaction coordinator",
  "Buyer transaction coordinator",
  "Seller closing attorney",
  "Buyer closing attorney",
  "Title company",
  "Escrow company",
  "Transaction auditor",
  "Lender",
  "Facilitator",
] as const;

const typeArray = [
  "Listing",
  "Buyer representation",
  "Purchase",
  "Refinance",
  "Rental",
  "Agent onboarding",
  "Set later",
] as const;

interface DotProps {
  color: string;
  onClick?: () => void;
  text: string;
  select?: boolean;
}

export const CreateTransaction: React.FC = () => {
  const { theme, logic } = useEloise();

  const { id } = useParams();

  const docPath = `transactions/${id}`;

  const [width, setWidth] = useState<6 | 9 | 12>(6);

  const [step, setStep] = useState<number>(1);

  const [role, setRole] = logic.fb.hooks.useThrottleField(docPath, "role");

  const [type, setType] = logic.fb.hooks.useThrottleField(docPath, "type");

  function getDotsWithSelected(selectedText: string): DotProps[] {
    const originalDots = [
      {
        color: theme.primary,
        text: "Information",
        onClick: () => setStep(2),
      },
      {
        color: theme.secondary,
        text: "Schedule",
        onClick: () => setStep(3),
      },
      {
        color: theme.accent,
        text: "Documents",
        onClick: () => setStep(4),
      },
      {
        color: theme.accent2,
        text: "Payments",
        onClick: () => setStep(5),
      },
      {
        color: theme.primary,
        text: "Title & Escrow",
        onClick: () => setStep(6),
      },
      {
        color: theme.secondary,
        text: "Inspections & Disclousures",
        onClick: () => setStep(7),
      },
      {
        color: theme.accent,
        text: "Closing Statements",
        onClick: () => setStep(8),
      },
      {
        color: theme.accent2,
        text: "Deed & Final Docs",
        onClick: () => setStep(9),
      },
      {
        color: theme.primary,
        text: "Review",
        onClick: () => setStep(10),
      },
      // ... more steps as needed
    ];

    return originalDots.map((dot) => ({
      ...dot,
      select: dot.text === selectedText,
    }));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const initTodos = async () => {
      const test = await logic.fb.docs.getUserDoc("transactions/" + id);

      if (test) {
        let test2 = test.data;

        if (!test2.todo) {
          let newTodos = ["docs", "pay", "tit", "ins", "clo", "dee"];
          await logic.fb.docs.setUserDoc("transactions/" + id, {
            ...test.data,
            todo: newTodos,
          });
        }
      }
    };

    initTodos();
  }, [id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (role && type && step === 1) {
      setWidth(12);
      setStep(2);
    }
  }, [role, type]);

  return (
    <EloiseWidget
      eloiseIntel={{
        title: "Create Transaction Page",
        desc: "page used to create a new transaction",
      }}>
      {/* Information */}
      {step < 3 && (
        <Row className="mx-auto">
          <Col
            lg={width}
            className="mx-auto"
            style={{ transition: "all ease 1s" }}>
            <HomeDiv
              style={{ minHeight: "78vh" }}
              className="container-fluid">
              <Row className="g-0 mb-3">
                {/* Header  */}
                <div
                  className={`mx-auto  order-sm-1   order-xs-1 order-lg-2  col-lg-${
                    width === 6 ? 6 : 4
                  } `}
                  style={{
                    backgroundColor: theme.primary,
                    borderRadius: "0 0 8px 8px",
                    padding: "13px 15px",
                    marginTop: "0",
                  }}>
                  <Heading
                    color="white"
                    size={3}>
                    New Transaction
                  </Heading>
                </div>
                {/* Display Step 1 Results when Gathered  */}
                {step !== 1 && (
                  <div className="col-lg-4  mx-auto order-sm-2 order-xs-2 order-lg-1 row mt-3">
                    <div className=" row col-lg-12">
                      {/* Role */}
                      <Col
                        lg={3}
                        className="mx-auto">
                        <Heading
                          size={1}
                          style={{ fontWeight: "400" }}
                          color="black">
                          Role
                        </Heading>
                      </Col>
                      <Col
                        lg={9}
                        className="mx-auto">
                        <DropDown
                          state={role}
                          setState={setRole}
                          options={[...options]}
                        />
                      </Col>
                    </div>
                  </div>
                )}

                {step !== 1 && (
                  <div className="col-lg-4 mx-auto order-sm-2 order-xs-2 order-lg-3  row mt-3">
                    {/* Type */}
                    <div className="row col-lg-12">
                      <Col
                        lg={3}
                        sm={3}
                        className="mx-auto">
                        <Heading
                          size={1}
                          style={{ fontWeight: "400" }}
                          color="black">
                          Type
                        </Heading>
                      </Col>
                      <Col
                        lg={9}
                        className="mx-auto">
                        <DropDown
                          state={type}
                          setState={setType}
                          options={[...typeArray]}
                        />
                      </Col>
                    </div>
                  </div>
                )}
              </Row>
              {/* Step 1  */}
              {step === 1 && (
                <Row>
                  <div>
                    <div className={"mx-auto mt-5"}>
                      <Col
                        lg={12}
                        className={`mx-auto row`}>
                        <Row>
                          {step === 1 && (
                            <Heading
                              size={2}
                              style={{ fontWeight: "400" }}
                              color="black">
                              {step === 1
                                ? "What is your role in this transaction?"
                                : "Role"}
                            </Heading>
                          )}

                          <DropDown
                            warning={step > 1}
                            warningMessage="Changing this at this point could lead to issues"
                            state={role}
                            setState={setRole}
                            options={[...options]}
                          />
                        </Row>
                      </Col>
                    </div>

                    <div className={"mx-auto mt-5"}>
                      <Col
                        lg={12}
                        className={`mx-auto row`}>
                        <Row>
                          <Heading
                            size={2}
                            style={{ fontWeight: "400" }}
                            color="black">
                            What type of transaction would you like to create?
                          </Heading>

                          <DropDown
                            state={type}
                            setState={setType}
                            options={[...typeArray]}
                          />
                        </Row>
                      </Col>
                    </div>

                    {/* <div className={"mx-auto mt-5"}>
                    <Col lg={12} className={`mx-auto row`}>
                      {type && role && <Button onClick={step1}>Create</Button>}
                    </Col>
                  </div> */}
                  </div>
                </Row>
              )}
              {/* Step 1  */}

              {/* Step 2 Basic Info and People + Deposits  */}
              {step === 2 && (
                <>
                  <Row>
                    {/* Basic Info  */}
                    <BasicInfo />
                    {/* Deposits  */}
                    <Deposits />
                    {/* People  */}
                    <Participants />
                  </Row>
                </>
              )}
            </HomeDiv>
          </Col>
          <Row>
            <Col
              lg={11}
              className="mx-auto">
              <Timeline dots={getDotsWithSelected("Information")} />
            </Col>
          </Row>
        </Row>
      )}

      {/* Dates */}
      {step === 3 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.secondary}
          heading="Schedule">
          <Schedule />
        </TrannyRow>
      )}
      {/* Documents */}
      {step === 4 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.accent}
          heading="Documents">
          <UploadOps
            heading="Documents"
            text="Upload Initial Documents Here"
            color={"accent"}
            docId="docs"
            path={`${id}/docs`}
          />
        </TrannyRow>
      )}

      {/* Payment */}
      {step === 5 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.accent2}
          heading="Payments">
          <UploadOps
            heading="Payments"
            text="Upload Reciept of Payment Here"
            color={"accent2"}
            docId="pay"
            path={`${id}/pay`}
          />
        </TrannyRow>
      )}
      {/*Title and Escrow */}

      {step === 6 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.primary}
          heading="Title & Escrow">
          <UploadOps
            heading="Title and Escrow"
            text="Upload Title and Escrow Documents Here"
            color={"primary"}
            docId="tit"
            path={`${id}/tit`}
          />
        </TrannyRow>
      )}
      {/*Inspections and Disclousures */}
      {step === 7 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.secondary}
          heading="Inspections & Disclousures">
          <UploadOps
            heading="Inspections & Disclousures"
            text="Upload Inspections and Disclouseres Here"
            color={"secondary"}
            docId="ins"
            path={`${id}/ins`}
          />
        </TrannyRow>
      )}
      {/*Closing Statements */}
      {step === 8 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.accent}
          heading="Closing Statements">
          <UploadOps
            heading="Closing Statements"
            text="Upload any Closing Statement related documents here"
            color={"accent"}
            docId="clo"
            path={`${id}/clo`}
          />
        </TrannyRow>
      )}
      {/*Closing Statements */}
      {step === 9 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.accent2}
          heading="Deeds & Final Docs">
          {" "}
          <UploadOps
            heading="Deeds & Final Docs"
            text="Upload any Deeds or Final Documents Here"
            color={"accent2"}
            docId="docs"
            path={`${id}/dee`}
          />
        </TrannyRow>
      )}
      {/* Review */}
      {step === 10 && (
        <TrannyRow
          getDots={getDotsWithSelected}
          width={width}
          color={theme.primary}
          heading="Review">
          <ReviewTranny />
        </TrannyRow>
      )}
    </EloiseWidget>
  );
};
