import React, { useState } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Button, DropDown, Heading, useEloise } from "typed-component-gallery";
import { ReactSearchAutocomplete } from "../search";

export interface PeopleInputProps {
  onAdd: Function;
  types: readonly string[];
}

export interface User {
  first: string;
  last: string;
  email: string;
  account: boolean;
  phone?: number;
  apps: Array<string>;
  usage?: {
    gpt?: number;
    api?: number;
  };
  username?: string;
}

export interface Contact {
  user: User;
  type: string;
  initials: string;
  email: string;
  first: string;
  last: string;
}

export const PersonDisplay = (item: Contact, type?: boolean) => {
  return (
    <div>
      <Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              backgroundColor: "blue",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}>
            {item.initials}
          </div>
          <span>{item.email}</span>
        </div>
      </Row>
      {type && (
        <Row>
          <Heading size={1}>{item.type}</Heading>
        </Row>
      )}
    </div>
  );
};

export const PeopleInput: React.FC<PeopleInputProps> = ({ onAdd, types }) => {
  const { theme, logic } = useEloise();

  const [showAddPersonModal, setShowAddPersonModal] = useState(false);

  const [person, setPerson] = useState();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [items, setItems] = useState<Array<any>>([]);

  const options = [...types] as const;

  logic.hooks.useAsyncEffect(async () => {
    let test = await logic.fb.contacts.getUserContacts();

    if (test) {
      const itemsWithNames = test.map((item: any) => ({
        ...item,
        name: `${logic.generic.capitalize(
          item.first
        )} ${logic.generic.capitalize(item.last)}`,
      }));

      setItems(itemsWithNames);
    }
  }, []);

  const handleOnSelect = (item: any) => {
    // the item selected
    setSelectedUser(item);
    setShowModal(true);
  };

  const handleSaveChanges = () => {
    onAdd({
      user: selectedUser,
      role,
      sendEmail,
    });

    // Close the modal after saving
    setSelectedUser(undefined);
    setShowModal(false);
  };

  const handleAddPerson = () => {
    setShowAddPersonModal(true);
  };

  const handleSaveNewPerson = () => {
    // Implement your logic to save the new person here.
    // Then close the modal.
    setShowAddPersonModal(false);
  };

  return (
    <div>
      <ReactSearchAutocomplete
        items={items}
        onSelect={handleOnSelect}
        autoFocus
        formatResult={PersonDisplay}
        fuseOptions={{ keys: ["first", "last", "email"] }}
        showNoResultsText={
          <div className="row p-1">
            <Button
              rounded
              onClick={handleAddPerson}>
              Add a Person Manually
            </Button>
          </div>
        }
      />
      {showAddPersonModal && (
        <Modal
          show={showAddPersonModal}
          onHide={() => setShowAddPersonModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Person</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* You'll want to create a form here for adding a new person */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowAddPersonModal(false)}>Cancel</Button>
            <Button onClick={handleSaveNewPerson}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}
      {selectedUser && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedUser?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Heading
              align="left"
              style={{ fontWeight: 400 }}
              size={2}>
              Role
            </Heading>
            <DropDown
              state={role}
              setState={setRole}
              options={[...options]}
            />
            <Form>
              <Form.Check
                type="checkbox"
                label="Send Email Notification"
                checked={sendEmail}
                onChange={(e) => setSendEmail(e.target.checked)}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            {role && <Button onClick={handleSaveChanges}>Save</Button>}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
