import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Button, FileTable, Heading, useEloise } from "typed-component-gallery";
import { GenLink } from "./generateLink";

export const UploadOps: React.FC<{
  color: "primary" | "secondary" | "accent" | "accent2";
  path: string;
  docId: string;
  heading: string;
  text: string;
}> = ({ color, path, docId, heading, text }) => {
  const { logic } = useEloise();
  const { id } = useParams();

  const [genLinkVis, setGenLinkVis] = useState(false);
  const [todo, setTodo] = useState(true);

  const [files, setFiles] = useState<any[]>([]);

  logic.hooks.useAsyncEffect(async () => {
    let test = await logic.fb.storageMethods.getUserStorageFolder(path);
    setFiles(test);
  }, []);

  const deleteFile = async (file: any) => {
    let test = await logic.fb.storageMethods.deleteFile(file.fullPath);
  };
  const openFile = (file: any) => {
    window.open(file.downloadURL, "_blank");
  };

  useEffect(() => {
    if (files.length > 0) {
      setTodo(false);
    }
  });

  const afterUpload = async () => {
    setTodo(false);

    const test = await logic.fb.docs.getUserDoc("transactions/" + id);
    if (test) {
      let test2 = test.data.todo;
      if (test2) {
        let newTodos = [...test2].filter((todo) => todo !== docId);
        await logic.fb.docs.setUserDoc("transactions/" + id, {
          ...test,
          data: { ...test.data, todo: newTodos },
        });
      }
    }
  };

  return (
    <Row>
      <Col
        lg={6}
        className="p-4">
        {genLinkVis ? (
          <GenLink
            message={heading}
            color={color}
            close={() => setGenLinkVis(false)}
            docId={docId}
          />
        ) : (
          <FileTable
            color={color}
            uploadCallBack={afterUpload}
            actions={[
              { text: "View", action: openFile },
              { text: "Sign", action: () => {} },
              { text: "Delete", action: deleteFile },
            ]}
            folderPath={"transactions/" + path}
          />
        )}
      </Col>
      <Col
        lg={6}
        className="px-4">
        <Row className="mt-4">
          <Heading
            size={3}
            align="left"
            color="black">
            {heading}
          </Heading>
          <Heading
            size={1}
            align="left"
            color="black">
            {text}
          </Heading>
        </Row>
        <Row>
          <Col
            lg={7}
            className="mt-3">
            <Button
              color={color}
              rounded
              onClick={() => {}}>
              {!todo ? "Add to Todos" : "Remove From Todos"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            lg={7}
            className="mt-3">
            <Button
              color={color}
              rounded
              onClick={() => {}}>
              Assign to Team
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            lg={7}
            className="mt-3">
            <Button
              color={color}
              rounded
              onClick={() => {
                setGenLinkVis(true);
              }}>
              Generate Link
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
