import React from "react";
import { Form } from "react-bootstrap";

export interface CheckboxProps {
  label: string;
  state: boolean;
  setState: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  state,
  setState,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.checked);
  };

  return (
    <Form.Group className="pt-3">
      <Form.Check
        type="checkbox"
        label={label}
        checked={state}
        onChange={handleCheckboxChange}
      />
    </Form.Group>
  );
};
