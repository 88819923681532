import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  Button,
  DBList,
  EloiseWidget,
  Heading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import { ListingCard } from "../";

import { mdiArrowUpBoldOutline } from "@mdi/js";
import Icon from "@mdi/react";

const HomeDiv = styled.div`
  margin: 10px;
  padding: 0 10px;
  border: 1px solid grey;
  border-radius: 8px;
  min-height: 78vh;
  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }
`;

const EmptyMessage = () => {
  const { theme } = useEloise();
  return (
    <Row className="mt-5">
      <Col lg={10}>
        <Heading size={3}>You don't have any listings yet.</Heading>
        <Heading size={2}>Click the create button to get started</Heading>
      </Col>
      <Col
        lg={2}
        className="text-center ">
        <Icon
          path={mdiArrowUpBoldOutline}
          size={4}
          style={{ color: theme.primary }}
        />
      </Col>
    </Row>
  );
};

export const Listing: React.FC<{}> = ({}) => {
  const { theme, logic, eloiseContent } = useEloise();

  const navigate = useNavigate();

  logic.hooks.useAsyncEffect(async () => {}, []);

  return (
    <>
      <EloiseWidget
        eloiseIntel={{ title: "Listing Page", desc: "test desc listing page" }}>
        <Row className="mx-auto">
          <Col lg={12}>
            <HomeDiv>
              <Row>
                <div
                  className="col-lg-3 "
                  style={{
                    backgroundColor: theme.primary,
                    borderRadius: "8px 0 8px 0",
                    padding: "13px 15px",
                    marginTop: "0",
                  }}>
                  <Heading
                    color="white"
                    size={3}>
                    My Listings
                  </Heading>
                </div>

                <Col lg={6}></Col>
                <Col
                  lg={3}
                  className="mt-2">
                  <Button
                    onClick={() => navigate("./create-listing/" + uuidv4())}
                    color="secondary">
                    New Listing
                  </Button>
                </Col>
              </Row>
              <Row className="mx-auto my-5">
                <DBList
                  path="listings"
                  Component={ListingCard}
                  Empty={EmptyMessage}
                />
              </Row>
            </HomeDiv>
          </Col>
        </Row>
      </EloiseWidget>
    </>
  );
};
