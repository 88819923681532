import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { Button, Heading, View, useEloise } from "typed-component-gallery";
import IconList from "./iconList";

import { mdiCashMultiple, mdiOffer, mdiSubtitlesOutline } from "@mdi/js";

import Icon from "@mdi/react";
import grade from "../../assets/card.png";
import docs from "../../assets/docs.png";
import offerCreate from "../../assets/offerCreate.png";
import tranny from "../../assets/tranny.png";

export const Dash: React.FC<{}> = ({}) => {
  const { theme, logic, eloiseContent } = useEloise();
  const [user, loading, error] = useAuthState(logic.fb.auth);
  const navigate = useNavigate();
  const gradeRef = useRef(null);
  const grade2Ref = useRef(null);

  const [testDate, setTestDate] = useState<Date | null>(null);

  return (
    <>
      <Row className="pt-4">
        <Col
          lg={6}
          className="mx-auto"
          style={{
            position: "relative",
          }}>
          <Row className="mx-3 mt-5">
            <Col lg={4}>
              <View>
                <Row>
                  <Heading
                    color={"black"}
                    size={3}>
                    Get Started Today
                  </Heading>
                  <Button
                    className="my-3"
                    purpose="navigate to login screen">
                    Login
                  </Button>
                  <Button purpose="navigate to signup screen">Signup</Button>
                </Row>
              </View>
            </Col>
            <Col
              lg={7}
              className="mx-auto text-center">
              <View>
                <img
                  src={grade}
                  width="250"
                />
              </View>
            </Col>
          </Row>

          <div
            className="row w-100"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "0 5px 5px 0",

              position: "absolute",
              bottom: 0,
            }}>
            <Heading
              color="white"
              style={{
                fontWeight: "400",
                padding: "5px",
              }}
              size={3}>
              Optimize Your Workflow
            </Heading>
          </div>
        </Col>
        <Col
          lg={6}
          className="mx-auto"
          style={{
            height: "70vh",
          }}>
          <div
            className="row"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "5px 0 0 5px ",
            }}>
            <Heading
              color="white"
              style={{
                fontWeight: "400",
                padding: "5px",
              }}
              size={3}>
              AI Powered Property Managment
            </Heading>
          </div>
          <Row className="mx-auto">
            <IconList
              items={[
                {
                  text: "Offer Managment",
                  icon: mdiOffer,
                  ref: gradeRef,
                },
                {
                  text: "Transaction Platform",
                  icon: mdiCashMultiple,
                  ref: gradeRef,
                },
                {
                  text: "Document Managment",
                  icon: mdiSubtitlesOutline,
                  ref: grade2Ref,
                },
              ]}
            />
          </Row>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col
          ref={gradeRef}
          lg={6}
          className="mx-auto"
          style={{
            position: "relative",
          }}>
          <Row className="mx-2">
            <Col
              lg={8}
              className="mx-auto text-center">
              {/* <View>
                                <img
                                    src={grade}
                                    width="400"
                                />
                            </View> */}
            </Col>
            <Col>
              <View style={{ flexDirection: "column" }}></View>
            </Col>
          </Row>

          <div
            className="row w-100"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "0 5px 5px 0",
              position: "absolute",
              bottom: 0,
            }}>
            <Col lg={10}>
              <View>
                <Heading
                  color="white"
                  style={{
                    fontWeight: "400",
                    padding: "5px",
                  }}
                  size={3}>
                  Transaction Platform
                </Heading>
              </View>
            </Col>

            <Col>
              <Icon
                path={mdiCashMultiple}
                size={2}
                color={"white"}
              />
            </Col>
          </div>
          <Row className="">
            <Col lg={12}>
              <View>
                <img
                  src={tranny}
                  width="600"
                />
              </View>
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          className="mx-auto"
          style={{
            height: "70vh",
          }}>
          <div
            className="row"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "5px 0 0 5px ",
            }}>
            <Col>
              <Icon
                path={mdiOffer}
                size={2}
                color={"white"}
              />
            </Col>
            <Col lg={10}>
              <View>
                <Heading
                  color="white"
                  style={{
                    fontWeight: "400",
                    padding: "5px",
                  }}
                  size={3}>
                  Offer Managment
                </Heading>
              </View>
            </Col>
          </div>
          <Row className=" mt-5">
            <Col lg={12}>
              <View>
                <img
                  src={offerCreate}
                  width="600"
                />
              </View>
            </Col>
          </Row>
        </Col>
      </Row>
      <div
        ref={grade2Ref}
        style={{
          height: "1vh",
        }}></div>
      <Row
        className="pt-4"
        style={{
          marginBottom: "20vh ",
        }}>
        <Col
          ref={gradeRef}
          lg={6}
          className="mx-auto"
          style={{
            position: "relative",
          }}>
          <Row className="mx-2">
            <Col
              lg={8}
              className="mx-auto text-center">
              {/* <View>
                                <img
                                    src={grade}
                                    width="400"
                                />
                            </View> */}
            </Col>
            <Col>
              <View style={{ flexDirection: "column" }}></View>
            </Col>
          </Row>

          <div
            className="row w-100"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "0 5px 5px 0",
              position: "absolute",
              bottom: 0,
            }}>
            <Col lg={10}>
              <View>
                <Heading
                  color="white"
                  style={{
                    fontWeight: "400",
                    padding: "5px",
                  }}
                  size={3}>
                  Transaction Platform
                </Heading>
              </View>
            </Col>

            <Col>
              <Icon
                path={mdiCashMultiple}
                size={2}
                color={"white"}
              />
            </Col>
          </div>
          <Row className="">
            <Col lg={12}>
              <View>
                <img
                  src={tranny}
                  width="600"
                />
              </View>
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          className="mx-auto"
          style={{
            height: "70vh",
          }}>
          <div
            className="row"
            style={{
              backgroundColor: theme.primary,
              fontWeight: "400",
              padding: "10px",
              borderRadius: "5px 0 0 5px ",
            }}>
            <Col>
              <Icon
                path={mdiSubtitlesOutline}
                size={2}
                color={"white"}
              />
            </Col>
            <Col lg={10}>
              <View>
                <Heading
                  color="white"
                  style={{
                    fontWeight: "400",
                    padding: "5px",
                  }}
                  size={3}>
                  Document Managment
                </Heading>
              </View>
            </Col>
          </div>
          <Row className=" mt-5">
            <Col lg={12}>
              <View>
                <img
                  src={docs}
                  width="600"
                />
              </View>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
    </>
  );
};
