import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Eloise,
  FirebaseConfig,
  SiteConfig,
  Theme,
} from "typed-component-gallery";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { fb } from "./config/fb";
import { site } from "./config/site";
import { themeConfig } from "./config/theme";

const theme: Theme = themeConfig;

const siteConfig: SiteConfig = site;

const fireBaseConfig: FirebaseConfig = fb;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Eloise
      theme={theme}
      siteConfig={siteConfig}
      fireBaseConfig={fireBaseConfig}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
