import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { DropDown, Heading, Input, useEloise } from "typed-component-gallery";

export interface BasicInfoProps {}

export const BasicInfo: React.FC<BasicInfoProps> = ({}) => {
  const { id } = useParams();
  const { logic } = useEloise();

  const docPath = `transactions/${id}`;

  const [transactionName, setTransactionName] = logic.fb.hooks.useThrottleField(
    docPath,
    "title"
  );
  const [status, setStatus] = logic.fb.hooks.useThrottleField(
    docPath,
    "status"
  );
  const [propertyAddress, setPropertyAddress] = logic.fb.hooks.useThrottleField(
    docPath,
    "propertyAddress"
  );
  const [propertyPrice, setPropertyPrice] = logic.fb.hooks.useThrottleField(
    docPath,
    "propertyPrice"
  );

  const statusOptions = ["Active", "Pending", "Closed"];

  return (
    <Col lg={4}>
      <Row className="px-3">
        {/* Basic Info  */}
        <Col lg={9}>
          <Heading
            size={3}
            style={{ fontWeight: 400 }}
            align="left">
            Basic Info
          </Heading>
          <Input
            style={{ border: `1px solid grey` }}
            rounded
            label="Transaction name"
            state={transactionName}
            setState={setTransactionName}
            extLabel
          />
        </Col>
      </Row>

      <Row className="px-3">
        <Col lg={9}>
          <Input
            style={{ border: `1px solid grey` }}
            rounded
            addy
            label="Property address"
            state={propertyAddress}
            setState={setPropertyAddress}
            extLabel
          />
        </Col>
      </Row>

      <Row className="px-3">
        <Col lg={9}>
          <Input
            style={{ border: `1px solid grey` }}
            rounded
            label="Property price"
            state={propertyPrice}
            setState={setPropertyPrice}
            extLabel
          />
        </Col>
      </Row>
      <Row className="px-3">
        <Col lg={9}>
          <DropDown
            label="Status"
            state={status}
            setState={setStatus}
            options={statusOptions}
          />
        </Col>
      </Row>
    </Col>
  );
};
