import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  Button,
  EloiseWidget,
  Heading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import house from "../../assets/house.png";
import { Event } from "../widgets/event";
import { Map } from "../widgets/map";
import { getTimePeriod } from "../widgets/schedule";
import { Dash } from "./dash";

const HomeDiv = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 8px;
  // &:hover{
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  // }
`;

const ConvoDiv = styled.div`
  border-bottom: 2px solid grey;
`;
const ShortedHead = styled(Heading)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DisplayListingOnMap = (location: any) => {
  const [offers, setOffers] = useState<any[]>([]);
  const navigate = useNavigate();
  const { theme, logic } = useEloise();
  const data = location.data.data;

  useEffect(() => {
    console.log(location);
  }, []);

  // logic.hooks.useAsyncEffect(async()=>{
  //     const test = await logic.fb.docs.getUserCollection("listings/"+ location.data.id + "/offers")
  //       setOffers(test)

  // },[])
  const update = async () => {};
  return (
    <Container
      style={{
        maxHeight: "150px",
        maxWidth: "150px",
        height: "150px",
        width: "150px",
        overflow: "hidden",
      }}>
      {/* <ListingCard data={location.data} update={update}/> */}
      <div className="text-center">
        <img
          src={house}
          height={75}
        />
        <Row className=" my-3">
          <Col
            lg={11}
            className="mx-auto">
            <Heading
              link={`/listings/listing-details/${location.data.id} `}
              size={2}
              color="black"
              style={{ fontWeight: "400" }}>{`${data.addy.street}`}</Heading>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export const Home: React.FC<{}> = ({}) => {
  const { logic } = useEloise();
  const navigate = useNavigate();

  const [user, loading, error] = useAuthState(logic.fb.auth);
  const [upcoming, setUpcoming] = useState<Event[]>([]);

  const [listings, setListings] = useState<Array<any>>([]);
  const [offers, setOffers] = useState<Array<any>>([]);
  const [tran, setTran] = useState<Array<any>>([]);

  logic.hooks.useAsyncEffect(async () => {
    let trans = await logic.fb.docs.getUserCollection("listings");
    if (trans) setTran(trans);
    let list = await logic.fb.docs.getUserCollection("listings");
    if (list) {
      setListings(list);
      let offersList = await Promise.all(
        list.map(async (item) => {
          return await logic.fb.docs.getUserCollection(
            "listings/" + item.id + "/offers"
          );
        })
      );
      setOffers(offersList.flat());
    }
  }, []);

  // update upcoming events whenever listings, offers or transactions change
  useEffect(() => {
    let events: Event[] = [];

    // assuming each item in listings, offers and tran can have an 'events' field
    [listings, offers, tran].forEach((collection) => {
      collection.forEach((item) => {
        if (item.events) {
          events.push(...item.events);
        }
      });
    });

    // sort events by start date
    events.sort((a, b) => a.data.start.getTime() - b.data.start.getTime());

    // get next three events
    let nextThreeEvents = events.slice(0, 3);

    setUpcoming(nextThreeEvents);
  }, [listings, offers, tran]);

  if (user) {
    return (
      <EloiseWidget
        eloiseIntel={{
          purpose: "User Dashboard Shows 'at a glance' data",
          title: "Dashboard",
          desc: "User Dashboard shows, listings, offers and transaction counts",
        }}>
        <Row>
          <Col
            lg={11}
            className="mx-auto">
            <HomeDiv style={{ minHeight: "78vh" }}>
              <Row className="mx-auto">
                <Col lg={6}>
                  <Heading
                    color="black"
                    align="left"
                    size={3}>
                    Dashboard
                  </Heading>
                </Col>
                <Col lg={3}>
                  <Button
                    onClick={() =>
                      navigate("/listings/create-listing/" + uuidv4())
                    }>
                    New Listing
                  </Button>
                </Col>
                <Col lg={3}>
                  <Button onClick={() => navigate("/listings")}>
                    View Listings
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={4}
                  className="pl-3 pt-3 mt-2">
                  <Row>
                    <Heading
                      size={3}
                      style={{ fontStyle: "italic" }}>
                      Summary
                    </Heading>
                  </Row>
                  <HomeDiv>
                    <Row className="my-2">
                      <Col xs={6}>
                        <Heading
                          align="right"
                          size={2}>
                          Active Listings:
                        </Heading>
                      </Col>
                      <Col xs={6}>
                        <Heading
                          align="left"
                          size={2}>{`${
                          listings ? listings.length : 0
                        }`}</Heading>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col xs={6}>
                        <Heading
                          align="right"
                          size={2}>
                          Offers:
                        </Heading>
                      </Col>
                      <Col xs={6}>
                        <Heading
                          align="left"
                          size={2}>{`${offers ? offers.length : 0}`}</Heading>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col xs={6}>
                        <Heading
                          align="right"
                          size={2}>
                          Transactions:
                        </Heading>
                      </Col>
                      <Col xs={6}>
                        <Heading
                          align="left"
                          size={2}>{`${tran ? tran.length : 0}`}</Heading>
                      </Col>
                    </Row>
                  </HomeDiv>
                  <Row>
                    <Heading
                      size={3}
                      link={"/schedule"}
                      style={{ fontStyle: "italic" }}>
                      Upcoming
                    </Heading>
                  </Row>
                  <HomeDiv>
                    {upcoming.length > 0 ? (
                      <Row>
                        {upcoming.map((event: Event) => (
                          <Row>
                            <Col lg={6}>
                              <Heading size={2}>
                                {event.data.type ?? "Appointment"}
                              </Heading>
                            </Col>
                            <Col lg={6}>
                              {getTimePeriod(event.data.start, event.data.end)}
                            </Col>
                          </Row>
                        ))}
                      </Row>
                    ) : (
                      <Row>
                        <Heading size={2}>No Upcoming Events</Heading>
                      </Row>
                    )}
                  </HomeDiv>
                </Col>
                <Col
                  lg={8}
                  className="mt-2">
                  <Map
                    clickable
                    Expand={DisplayListingOnMap}
                    path="listings"
                  />
                </Col>
              </Row>
            </HomeDiv>
          </Col>
        </Row>
      </EloiseWidget>
    );
  } else {
    return <Dash />;
  }
};
