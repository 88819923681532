import {
  mdiAccountGroupOutline,
  mdiAccountTie,
  mdiClipboardListOutline,
  mdiHome,
} from "@mdi/js";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Heading, Input, useEloise } from "typed-component-gallery";
import IconList from "./iconList";
import { HomeDiv } from "./transactions";

const Divider = styled.div`
  height: 1px;
  width: 80%;
  margin: 30px auto;
  border-bottom: 3px solid;
`;

export const Account = () => {
  const { logic, theme } = useEloise();

  const [role, setRole] = logic.fb.hooks.useThrottleField("", "role");
  const [first, setFirst] = logic.fb.hooks.useThrottleUserField("first");
  const [last, setLast] = logic.fb.hooks.useThrottleUserField("last");
  const [phone, setPhone] = logic.fb.hooks.useThrottleUserField("phone");
  const [preName, setPreName] = logic.fb.hooks.useThrottleUserField("preName");
  /* //Todo add Sub Bar
   const [perm, setPerm]=  logic.fb.hooks.useThrottleField("", "perm");
   const [trialDate, setTrialDate]=  logic.fb.hooks.useThrottleField("", "trialDate");
  */

  logic.hooks.useAsyncEffect(async () => {
    let test = await logic.fb.docs.getUser();
    console.log(test);
  }, []);

  return (
    <Container>
      <Row>
        <Col
          lg={11}
          className="mx-auto">
          <HomeDiv style={{ minHeight: "78vh" }}>
            <Row className="mx-auto">
              <Col lg={6}>
                <Heading
                  color="black"
                  align="left"
                  style={{ fontStyle: "italic" }}
                  size={3}>
                  Settings
                </Heading>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col
                lg={3}
                className="py-5">
                <Heading size={3}>Account</Heading>
                <Heading size={1}>Basic Info</Heading>
              </Col>
              <Col lg={9}>
                <Row className="my-4">
                  <Col
                    lg={5}
                    className="mx-auto">
                    <Input
                      extLabel
                      label="First Name"
                      state={first}
                      setState={setFirst}
                    />
                  </Col>
                  <Col
                    lg={5}
                    className="mx-auto">
                    <Input
                      extLabel
                      label="Last Name"
                      state={last}
                      setState={setLast}
                    />
                  </Col>
                  <Col
                    lg={5}
                    className="mx-auto">
                    <Input
                      extLabel
                      label="Prefered Name"
                      state={preName}
                      setState={setPreName}
                    />
                  </Col>
                  <Col
                    lg={5}
                    className="mx-auto">
                    <Input
                      extLabel
                      label="Phone Number"
                      state={phone}
                      setState={setPhone}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider style={{ borderBottomColor: theme.primary }}></Divider>
            <Row className="mt-4">
              <Col
                lg={3}
                className="py-5">
                <Heading size={3}>Mode</Heading>
                <Heading size={1}>
                  This should best describe your role. This will change how the
                  app behaves
                </Heading>
              </Col>
              <Col lg={9}>
                <IconList
                  selected={role}
                  setSelected={setRole}
                  items={[
                    {
                      text: "Buyer/Seller",
                      icon: mdiHome,
                    },
                    {
                      text: "Agent",
                      icon: mdiAccountTie,
                    },
                    {
                      text: "Property Manager",
                      icon: mdiClipboardListOutline,
                    },
                    {
                      text: "Agent Manager",
                      icon: mdiAccountGroupOutline,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider style={{ borderBottomColor: theme.primary }}></Divider>

            <Row className="mt-4">
              <Col
                lg={3}
                className="py-5">
                <Heading size={3}>Plan</Heading>
                <Heading size={1}>Manage your Subscription</Heading>
              </Col>
              <Col lg={9}></Col>
            </Row>
            {/* <Divider style={{ borderBottomColor: theme.primary }}></Divider>

            <Row className="mt-4">
              <Col
                lg={3}
                className="py-5">
                <Heading size={3}>Color</Heading>
                <Heading size={1}>Customize the look of the app</Heading>
              </Col>
              <Col lg={9}></Col>
            </Row> */}
          </HomeDiv>
        </Col>
      </Row>
    </Container>
  );
};
