import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

interface DotProps {
  color: string;
  onClick?: () => void;
  text: string;
  select?: boolean;
}

const DotText = styled.div`
  position: absolute;
  top: 35px; // position the text below the dot
  width: 100%;
  text-align: center; // center align the text
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center; // center the dot horizontally within the column
  position: relative; // relative positioning so the DotText can be positioned relative to this
`;
const StyledDot = styled.div<DotProps>`
  position: relative;
  width: 25px;
  height: 25px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
`;

const StyledRow = styled(Row)`
  position: relative;
  align-items: center; // aligns all the children in the center
`;
const Line = styled.div`
  position: absolute;
  height: 2px;
  background-color: grey;
  width: calc(
    100% - 145px
  ); // subtracting the approximate space of first and last dot
  left: 75px; // shifting the line to start from the center of the first dot
  top: 50%;
`;

const TimelineDot: React.FC<DotProps> = ({ color, onClick, text, select }) => {
  return (
    <StyledCol>
      <StyledDot
        select={select}
        color={color}
        onClick={onClick}
        text={text}
      />
      <DotText
        style={
          select ? { textDecoration: `underline 3px solid ${color}` } : {}
        }>
        {text}
      </DotText>
    </StyledCol>
  );
};

interface TimelineProps {
  dots: DotProps[];
}

const Timeline: React.FC<TimelineProps> = ({ dots }) => {
  return (
    <StyledRow>
      <Line />
      {dots.map((dot, i) => (
        <TimelineDot
          key={i}
          {...dot}
        />
      ))}
    </StyledRow>
  );
};

export default Timeline;
